<template>
  <div class="fluid">
    <v-toolbar flat>
      <v-toolbar-title>授信列表</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-text-field v-model="query.key" label="关键字搜索（按回车）" prepend-inner-icon="search"
        :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'" solo flat dense hide-details clearable
        @keydown.enter="searchItem" @click:clear="clearSearchItem"></v-text-field>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="mx-2" depressed @click="doAction('acctscan_item')">
            准入尽职调查
          </v-btn>
        </template>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ma-2" depressed color="secondary" @click="doAction('create_item')">
            <v-icon>add</v-icon> 风控计算
          </v-btn>
        </template>
      </v-menu>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
            <v-icon small>filter_list</v-icon>
          </v-btn>
        </template>
        <span>过滤数据</span>
      </v-tooltip>
    </v-toolbar>
    <v-tabs v-model="tab">
      <v-tab>集团授信</v-tab>
      <v-tab>企业授信</v-tab>
      <v-tab-item>
        <v-data-table :headers="headerTableEntGroup" :items="riskEntGroupList" :options.sync="optionsTable"
          :server-items-length="riskEntGroupCount" :loading="riskEntGroupLoading" @item-expanded="onRowExpanded" loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" fixed-header height="600" item-key="_id"
          transition :single-expand="true" show-expand>
          <template v-slot:expanded-item="{ headers }">
            <td :colspan="headers.length" style="padding: 16px">
              <v-data-table :headers="headerTable1" :items="riskEnterpriseList1" :options.sync="optionsTable1"
                :server-items-length="riskEnterpriseCount1" :loading="riskEnterpriseLoading1" loading-text="数据读取中... 请稍后"
                :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" fixed-header item-key="_id"
                transition>
                <template v-slot:item.model.businessType="{ item }">
                  <td nowrap="true">{{businessTypeList.find(o => o.code === item.model?.businessType)?.name}}</td>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{item.updateTime | formatTime}}</td>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        icon
                        small
                      >
                        <v-icon small>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list dense>
                      <v-list-item class="v-list-item--ink" @click="doAction('detail', item)">
                        <v-list-item-icon>
                          <v-icon small>edit_note</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>计算详情</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="v-list-item--ink" @click="doAction('refresh', item)">
                        <v-list-item-icon>
                          <v-icon small>autorenew</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>刷新额度</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="v-list-item--ink" @click="doAction('adjust', item)">
                        <v-list-item-icon>
                          <v-icon small>adjust</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>调整额度</v-list-item-title>
                      </v-list-item>
                      <v-list-item class="v-list-item--ink" @click="doAction('logs', item)">
                        <v-list-item-icon>
                          <v-icon small>history</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>变更记录</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-slot:no-data>
                  <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
                </template>
              </v-data-table>
            </td>
          </template>
          <template v-slot:item.creditLine="{ item }">
            <td nowrap="true">
              {{item.creditLine}}
              <v-tooltip bottom v-if="item.creditLine && item.creditLineMax && (item.creditLine > item.creditLineMax)">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="red" small>info</v-icon>
                </template>
                <span>集团总授信额度已超出集团授信额度峰值</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:item.updateTime="{ item }">
            <td nowrap="true">{{item.updateTime | formatTime}}</td>
          </template>
          <template v-slot:item.actions="{ item }">
            <td nowrap="true">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="doAction('adjustGroup', item)">adjust</v-icon>
                </template>
                <span>调整集团额度</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="doAction('showLog', item)">history</v-icon>
                </template>
                <span>变更记录</span>
              </v-tooltip>
            </td>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-data-table :headers="headerTable" :items="riskEnterpriseList" :options.sync="optionsTable"
          :server-items-length="riskEnterpriseCount" :loading="riskEnterpriseLoading" loading-text="数据读取中... 请稍后"
          :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" fixed-header height="600" item-key="_id"
          transition>
          <template v-slot:item.model.businessType="{ item }">
            <td nowrap="true">{{businessTypeList.find(o => o.code === item.model.businessType)?.name}}</td>
          </template>
          <template v-slot:item.updateTime="{ item }">
            <td nowrap="true">{{item.updateTime | formatTime}}</td>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                >
                  <v-icon small>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item class="v-list-item--ink" @click="doAction('detail', item)">
                  <v-list-item-icon>
                    <v-icon small>edit_note</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>计算详情</v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--ink" @click="doAction('refresh', item)">
                  <v-list-item-icon>
                    <v-icon small>autorenew</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>刷新额度</v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--ink" @click="doAction('adjust', item)">
                  <v-list-item-icon>
                    <v-icon small>adjust</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>调整额度</v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--ink" @click="doAction('logs', item)">
                  <v-list-item-icon>
                    <v-icon small>history</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>变更记录</v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item--ink" @click="doAction('syncKingdee', item)">
                  <v-list-item-icon>
                    <v-icon small>sync_alt</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>同步金蝶</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
          </template>
        </v-data-table>
        <v-navigation-drawer v-model="filterDrawer" app right>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="title">
                过滤条件
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-card-text>
            <h2 class="subtitle-2 mt-2">风控模型</h2>
            <v-autocomplete class="mt-2" v-model="query.modelId" :items="riskModelList" @change="setFilter" item-text="name"
              item-value="_id" autocomplete="off" outlined dense label="风控模型" hint="请选择风控模型" chips deletable-chips multiple
              small-chips>
            </v-autocomplete>
          </v-card-text>
        </v-navigation-drawer>
      </v-tab-item>
    </v-tabs>
    <v-dialog v-model="dlgEdit" scrollable persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            {{ dlgTitle }}
            <v-spacer></v-spacer>
            <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-if="dlgTitle === '风控计算' || dlgTitle === '准入尽职调查'"
                    v-model="editedItem.enterpriseId"
                    :items="enterpriseList"
                    :loading="isEnterpriseLoading"
                    :search-input.sync="searchEnterprise"
                    :rules="[rules.required, rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择企业"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="dlgTitle === '风控计算'"
                    v-model="editedItem.modelId"
                    :items="riskModelList"
                    :rules="[rules.required, rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择风控模型"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-if="dlgTitle === '风控计算'"
                    v-model="editedItem.enterpriseTypeId"
                    :items="riskEnterpriseTypeList"
                    :rules="[rules.required, rules.selected]"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    chips
                    small-chips
                    label="请选择企业授信类型"
                  ></v-autocomplete>
                  <v-text-field
                    v-if="dlgTitle === '调整授信额度' || dlgTitle === '调整集团授信额度'"
                    v-model="editedItem.creditLine"
                    :rules="[rules.required, rules.selected]"
                    type="number"
                    label="授信额度（元）"
                    hint="请输入授信额度"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">取消</v-btn>
            <v-btn color="secondary" text :disabled="!valid" @click="submit">{{ dlgTitle === '风控计算' ? '计算': '确定'}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dlgLog" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          变更记录
          <v-spacer></v-spacer>
          <v-btn icon @click="closeLog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <risk-action-logs :id="entGroupId" :key="dateKey"></risk-action-logs>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgKingdee" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            同步金蝶信用额度
            <v-spacer></v-spacer>
            <v-btn icon @click="closeKingdee"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div>
                企业名称：{{ curRiskEnterprise.enterprise?.name }}
              </div>
              <div>
                授信总额度：{{ curRiskEnterprise.totalCreditLine }}
              </div>
              <v-data-table :headers="headerTableKingdee" :items="kingdeeCustomerList" :options.sync="optionsTableKingdee"
                :server-items-length="kingdeeCustomerCount" :loading="kingdeeCustomerLoading" loading-text="数据读取中... 请稍后"
                :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" :hide-default-footer="true" fixed-header item-key="_id"
                transition>
                <template v-slot:item.creditLine="props">
                  <v-edit-dialog :return-value.sync="props.item.creditLine">
                    {{ props.item.creditLine }}<v-icon small>edit</v-icon>
                    <template v-slot:input>
                      <v-text-field
                        type="number"
                        v-model="props.item.creditLine"
                        label="授信额度"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.updateTime="{ item }">
                  <td nowrap="true">{{item.updateTime | formatTime}}</td>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeKingdee">取消</v-btn>
            <v-btn color="secondary" text @click="submitKingdee">确定</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>
<script>
import _ from 'lodash';
import store from "@/store";
import {
  FETCH_ENTERPRISE_LIST,
  FETCH_RISK_ENTERPRISE_LIST,
  CAL_CREDITLINE,
  FETCH_RISK_MODEL_LIST,
  FETCH_RISK_ENTERPRISETYPE_LIST,
  EDIT_RISK_ENTERPRISE,
  REFRESH_RISK_ENTERPRISE,
  RE_CAL_CREDITLINE,
  FETCH_RISK_ENTGROUP_LIST,
  ADJUST_RISK_ENTGROUP_CREDITLINE,
  FETCH_RISK_LOG_LIST,
  FETCH_RISK_ENTERPRISE,
  FETCH_KINGDEE_CUSTOMER_LIST,
  UPDATE_KINGDEE_CREDITLINE,
  FETCH_QCC_NAME_SEARCH_LIST,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      tab: 0,
      riskEnterpriseLoading: false,
      riskEnterpriseList: [],
      riskEnterpriseCount: 0,
      isEnterpriseLoading: false,
      enterpriseList: [],
      searchEnterprise: null,
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: '',
        id: '',
        enterpriseId: '',
        modelId: '',
        enterpriseTypeId: '',
        creditLine: '',
      },
      defaultItem: {
        _id: '',
        id: '',
        enterpriseId: '',
        modelId: '',
        enterpriseTypeId: '',
        creditLine: '',
      },
      filterDrawer: false,
      query: {
        key: '',
        modelId: [],
      },
      defaultQuery: {
        key: '',
        modelId: [],
      },
      headerTableEntGroup: [
        { text: '', value: 'data-table-expand' },
        {text: '集团名称', width: '125px', value: 'entGroupName'},
        {text: '总授信额度（元）', width: '125px', value: 'creditLine'},
        {text: '集团授信额度峰值（元）', width: '125px', value: 'creditLineMax'},
        {text: '企业数量', width: '125px', value: 'count'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      headerTable: [
        {text: '企业名称', width: '300px', value: 'enterprise.name'},
        {text: '企业授信类型', width: '200px', value: 'enterpriseType.name'},
        {text: '企查查集团', width: '200px', value: 'enterpriseQcc.groupName'},
        {text: '模型名称', width: '125px', value: 'model.name'},
        {text: '业务类型', width: '125px', value: 'model.businessType'},
        {text: '授信额度（元）', width: '125px', value: 'creditLine'},
        {text: '得分', width: '125px', value: 'totalScore'},
        {text: '备注', width: '125px', value: 'remarks'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      entGroupId: '',
      expandedItemId: null,
      riskEnterpriseLoading1: false,
      riskEnterpriseList1: [],
      riskEnterpriseCount1: 0,
      headerTable1: [
        {text: '企业名称', width: '300px', value: 'enterprise.name'},
        {text: '企业授信类型', width: '200px', value: 'enterpriseType.name'},
        {text: '企查查集团', width: '200px', value: 'enterpriseQcc.groupName'},
        {text: '模型名称', width: '125px', value: 'model.name'},
        {text: '业务类型', width: '125px', value: 'model.businessType'},
        {text: '授信额度（元）', width: '125px', value: 'creditLine'},
        {text: '得分', width: '125px', value: 'totalScore'},
        {text: '备注', width: '125px', value: 'remarks'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable1: {},
      dlgLog: false,
      dateKey: '',
      dlgKingdee: false,
      curRiskEnterprise: {},
      headerTableKingdee: [
        {text: '签约主体名称', width: '250px', value: 'orgName'},
        {text: '金蝶授信额度', width: '125px', value: 'quota'},
        {text: '授信额度', width: '130px', value: 'creditLine'},

      ],
      optionsTableKingdee: {},
      kingdeeCustomerList: [],
      kingdeeCustomerCount: 0,
      kingdeeCustomerLoading: false,
    }
  },
  async mounted() {
    await this.getRiskEntGroupList();
    await this.getEnterpriseList();
    await this.getModelList();
    await this.getRiskEnterpriseTypeList();
  },
  async created() {
    await this.getDataList()
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'currentEmployer',
      'riskModelList',
      'riskEnterpriseTypeList',
      'riskEntGroupLoading',
      'riskEntGroupList',
      'riskEntGroupCount',
    ]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
    searchEnterprise: {
      handler: _.debounce(function (val) {
        if (val) {
          if (this.isEnterpriseLoading) return;
          this.isEnterpriseLoading = true;
          store
            .dispatch(FETCH_ENTERPRISE_LIST, '&key=' + val)
            .then((data) => {
              this.enterpriseList = data.enterprise;
              this.isEnterpriseLoading = false;

              // 如果没有搜索到企业，再搜索企查查
              if (data.enterprise.length == 0) {
                this.isEnterpriseLoading = true;
                store
                  .dispatch(FETCH_QCC_NAME_SEARCH_LIST, {
                    params: {
                      key: val,
                    }
                  })
                  .then((data) => {
                    this.enterpriseList = data.docs.map(n => ({
                      _id: n.Name,
                      name: n.Name,
                    }));
                    this.isEnterpriseLoading = false;
                  })
                  .catch((err) => {
                    store.commit(SET_ERROR, { msg: err });
                    this.isEnterpriseLoading = false;
                  });
              }
            })
            .catch((err) => {
              store.commit(SET_ERROR, { msg: err });
              this.isEnterpriseLoading = false;
            });
        }
      }, 500)
    },
  },
  methods: {
    async doAction(action, item = {}) {
      switch (action) {
        case 'acctscan_item': {
          this.dlgEdit = true;
          this.dlgTitle = '准入尽职调查';
          break
        }
        case 'create_item': {
          this.dlgEdit = true;
          this.dlgTitle = '风控计算';
          break
        }
        case 'detail': {
          this.$router.push({ path: '/dealer/system/risk/enterprise/detail/' + item._id })
          break
        }
        case 'refresh': {
          store.commit(SET_ERROR, { msg: '正在同步企查查、金蝶信息……', color: 'primary' });
          store.dispatch(REFRESH_RISK_ENTERPRISE, {
            enterpriseId: item.enterprise.id,
            type: [1, 2],
          })
            .then(() => {
              store.dispatch(RE_CAL_CREDITLINE, {
                riskEnterpriseId: item.id,
              })
                .then(() => {
                  store.commit(SET_ERROR, { msg: '刷新成功', color: 'primary' });
                });
            })
            .catch((error) => {
              store.commit(SET_ERROR, { msg: error.response.data.message });
            })
          break;
        }
        case 'adjust': {
          this.editedItem._id = item._id;
          this.editedItem.creditLine = item.creditLine;

          this.dlgEdit = true;
          this.dlgTitle = '调整授信额度';
          break
        }
        case 'logs': {
          this.$router.push({ path: '/dealer/system/risk/enterprise/detail/' + item._id + '?showlog=1' })
          break
        }
        case 'syncKingdee': {
          this.dlgKingdee = true;

          // 获取当前同步金蝶企业信息
          const riskEnterprise = await this.$store.dispatch(FETCH_RISK_ENTERPRISE, item._id);
          this.curRiskEnterprise = riskEnterprise;

          try {
            this.kingdeeCustomerLoading = true;
            const data = await store.dispatch(FETCH_KINGDEE_CUSTOMER_LIST, 'name=' + item.enterprise.name);
            this.kingdeeCustomerList = data.docs;
            this.kingdeeCustomerCount = data.docs.length;
            this.kingdeeCustomerLoading = false;
          } catch (e) {
            this.kingdeeCustomerLoading = false;
            console.error(e);
          }

          break
        }
        case 'adjustGroup': {
          this.editedItem.id = item._id;
          this.editedItem.creditLine = item.creditLine;

          this.dlgEdit = true;
          this.dlgTitle = '调整集团授信额度';
          break
        }
        case 'showLog': {
          this.entGroupId = item._id;
          this.dateKey = new Date().getTime(); // 为了每次刷新日志组件

          this.dlgLog = true;
          break
        }
      }
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    async getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.modelId.length) urlQuery += '&modelId='+ encodeURIComponent(this.query.modelId)
      if (Object.keys(this.optionsTable).length > 0) {
        try {
          this.riskEnterpriseLoading = true;
          const data = await store.dispatch(FETCH_RISK_ENTERPRISE_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery);
          this.riskEnterpriseList = data.docs;
          this.riskEnterpriseCount = data.query.count;
          this.riskEnterpriseLoading = false;
        } catch (e) {
          this.riskEnterpriseLoading = false;
          console.error(e);
        }
      }
    },
    async getEnterpriseList() {
      store.dispatch(FETCH_ENTERPRISE_LIST);
    },
    async getModelList() {
      store.dispatch(FETCH_RISK_MODEL_LIST);
    },
    async getRiskEnterpriseTypeList() {
      store.dispatch(FETCH_RISK_ENTERPRISETYPE_LIST);
    },
    async getRiskEntGroupList() {
      store.dispatch(FETCH_RISK_ENTGROUP_LIST);
    },
    async getRiskEnterpriseList1(entGroupId) {
      try {
        this.riskEnterpriseLoading1 = true;
        const data = await store.dispatch(FETCH_RISK_ENTERPRISE_LIST, `entGroupId=${entGroupId}`);
        this.riskEnterpriseList1 = data.docs;
        this.riskEnterpriseCount1 = data.query.count;
        this.riskEnterpriseLoading1 = false;
      } catch (e) {
        this.riskEnterpriseLoading1 = false;
        console.error(e);
      }
    },
    async submit() {
      if (this.dlgTitle === '风控计算') {
        let strInsert = {}
        strInsert.enterpriseId = this.editedItem.enterpriseId;
        strInsert.modelId = this.editedItem.modelId;
        strInsert.enterpriseTypeId = this.editedItem.enterpriseTypeId;

        store.dispatch(CAL_CREDITLINE, strInsert)
          .then(() => {
            store.commit(SET_ERROR, { msg: '创建成功', color: 'primary' });
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          })
      }

      if (this.dlgTitle === '调整授信额度') {
        let strUpdate = {}
        strUpdate._id = this.editedItem._id;
        strUpdate.creditLine = parseFloat(this.editedItem.creditLine);

        store.dispatch(EDIT_RISK_ENTERPRISE, strUpdate)
          .then(() => {
            store.commit(SET_ERROR, { msg: '修改成功', color: 'primary' });
            this.query.key = ''
            this.query.filterItem = [];
            this.getDataList();
            this.getRiskEntGroupList();
            if (this.expandedItemId) {
              this.getRiskEnterpriseList1(this.expandedItemId);
            }
            this.close();
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          })
      }

      if (this.dlgTitle === '调整集团授信额度') {
        let strUpdate = {}
        strUpdate.id = this.editedItem.id;
        strUpdate.creditLine = parseFloat(this.editedItem.creditLine);

        store.dispatch(ADJUST_RISK_ENTGROUP_CREDITLINE, strUpdate)
          .then(() => {
            store.commit(SET_ERROR, { msg: '修改成功', color: 'primary' });
            this.query.key = ''
            this.query.filterItem = [];
            this.getDataList();
            this.getRiskEntGroupList();
            if (this.expandedItemId) {
              this.getRiskEnterpriseList1(this.expandedItemId);
            }
            this.close();
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          })
      }

      if (this.dlgTitle === '准入尽职调查') {
        const enterprise = this.enterpriseList.find(n => n._id == this.editedItem.enterpriseId);
        this.$router.push({ path: '/dealer/system/risk/enterprise/acctscan/' + enterprise.name })

        this.close();
      }
    },
    async submitKingdee() {
      const totalCreditLine = this.kingdeeCustomerList.reduce((acc, item) => {
        return acc + (item.creditLine ? parseFloat(item.creditLine) : 0);
      }, 0);

      if (totalCreditLine > this.curRiskEnterprise.totalCreditLine) {
        store.commit(SET_ERROR, { msg: `授信额度${totalCreditLine}不能大于授信总额度${this.curRiskEnterprise.totalCreditLine}，请修改！` });
        return;
      }

      const data = this.kingdeeCustomerList.filter(n => n.creditLine && (parseFloat(n.creditLine) != n.quota));

      try {
        if (data.length>0) {
          await store.dispatch(UPDATE_KINGDEE_CREDITLINE, data);

          this.closeKingdee();
        }
      } catch (error) {
        store.commit(SET_ERROR, { msg: error.response.data.message });
      }
    },
    close() {
      this.dlgTitle = '';
      this.dlgEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    closeLog() {
      this.dlgLog = false;
    },
    closeKingdee() {
      this.dlgKingdee = false;
    },
    async onRowExpanded(param) {
      if (param.value) {
        this.expandedItemId = param.item._id;
        await this.getRiskEnterpriseList1(param.item._id);
      } else {
        this.expandedItemId = null;
      }
    },
    async getLogList(id) {
      store.dispatch(FETCH_RISK_LOG_LIST, '&limit=100&key=' + id)
        .then((logs) => {
          this.logList = logs.logs;
        })
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

