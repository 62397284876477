var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("准入尽职调查结果")])], 1), _c('v-card-text', [_c('v-subheader', [_vm._v("风险等级")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 企业名称 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.CompanyName) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 风险等级 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.RiskLevel) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 尽调评分 ")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" " + _vm._s(_vm.editedItem.RiskScore) + " ")])], 1), _c('v-subheader', [_vm._v("尽调结果")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.editedItem.Data,
      "loading": _vm.qccAcctScanLoading,
      "loading-text": "数据读取中... 请稍后",
      "fixed-header": "",
      "height": "600",
      "transition": ""
    },
    scopedSlots: _vm._u([{
      key: "item.DetailId",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          staticClass: "text-truncate",
          staticStyle: {
            "max-width": "150px"
          },
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.DetailId))])];
      }
    }])
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }