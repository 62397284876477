<template>
  <v-container fluid>
    <v-form v-model="valid" @submit.prevent="submit">
      <v-autocomplete v-model="editedItem.enterpriseName" :items="cusNameList" :loading="isCusNameListLoading"
        :search-input.sync="searchCusName" :rules="[rules.required]" item-text="Name" item-value="Name"
        autocomplete="off" clearable label="输入企业名称，可模糊查询"></v-autocomplete>

      <v-checkbox v-model="editedItem.isRiskFactor" label="查询租赁业务风控系数">
      </v-checkbox>

      <v-autocomplete v-if="editedItem.isRiskFactor" v-model="editedItem.riskEnterpriseTypeId"
        :items="riskEnterpriseTypeList" :rules="[rules.selected]" item-text="name" item-value="_id" autocomplete="off"
        label="授信资质分类*" hint="请选择授信资质分类"></v-autocomplete>
      <div class="d-flex justify-center">
        <v-btn color="secondary" @click="submit" :disabled="!valid">查询</v-btn>
      </div>
    </v-form>
    <v-list dense v-if="showResult">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>余额表时间：{{ ysmxCreateTime }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>查询时间：{{ date }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>查询人：{{ currentUser.personal.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-if="showResult">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ this.enterprise?.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>hail</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>销售负责人</v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{ this.enterprise?.account_sales?.personal?.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>contact_phone</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>客户联系人</v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{ this.enterprise?.contact_name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>corporate_fare</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>隶属集团</v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{ this.enterprise?.ent_group?.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>room</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>公司地址</v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{ this.enterprise?.address }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>engineering</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-subtitle>公司所属行业</v-list-item-subtitle>
            <v-list-item-title class="text-wrap">{{ this.enterprise?.industry?.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list-item v-if="editedItem.isRiskFactor">
        <v-list-item-content>
          <v-list-item-title>租赁业务风控系数</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ '1%' }}
        </v-list-item-action>
      </v-list-item>
      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          已授信总额度
          <v-spacer></v-spacer>
          {{ totalCreditLine | formatMoney }}
        </v-card-title>
        <v-card-text>
          <v-list-item v-for="(item, i) in kingdeeCustomerList" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ item.orgName }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-content>
              <v-list-item-title>{{ item.days }}天</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ item.quota | formatMoney }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          占用授信总额度
          <v-spacer></v-spacer>
          {{ receivableTotal | formatMoney }}
        </v-card-title>
        <v-card-text>
          <v-list-item v-for="(item, i) in receivableOverdueSummary.list" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ item.FZZ }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.total != null">
              {{ item.total | formatMoney }}</v-list-item-action>
            <v-list-item-action v-else>-</v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>

      <v-img alt="超期计算公式" class="shrink my-2" contain src="/static/risk/overdue.png"
        transition="scale-transition" @click="dialogImg = true"></v-img>
      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          超期总金额
          <v-spacer></v-spacer>
          {{ (ysTotal + zlTotal) | formatMoney }}
        </v-card-title>
        <v-card-text>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>应收超期</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              {{ ysTotal | formatMoney }}
            </v-list-item-action>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>账龄超期</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              {{ zlTotal | formatMoney }}
            </v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          应收超期
          <v-spacer></v-spacer>
          {{ ysTotal | formatMoney }}
        </v-card-title>
        <v-list>
          <v-list-group
            v-for="(item, i) in receivableOverdue.ysList"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.FZZ"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.overdueTotal | formatMoney }}
              </v-list-item-action>
            </template>

            <v-list-item
              v-for="(detail, index) in item.list"
              :key="index"
              @click="showDlg(detail)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="detail.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="detail.total != null">
                {{ detail.total | formatMoney }}</v-list-item-action>
              <v-list-item-action v-else>-</v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          账龄超期
          <v-spacer></v-spacer>
          {{ zlTotal | formatMoney }}
        </v-card-title>
        <v-list>
          <v-list-group
            v-for="(item, i) in receivableOverdue.zlList"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.FZZ"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                {{ item.overdueTotal | formatMoney }}
              </v-list-item-action>
            </template>

            <v-list-item
              v-for="(detail, index) in item.list"
              :key="index"
              @click="showDlg(detail)"
            >
              <v-list-item-content>
                <v-list-item-title v-text="detail.name"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="detail.total != null">
                {{ detail.total | formatMoney }}</v-list-item-action>
              <v-list-item-action v-else>-</v-list-item-action>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-card>

      <v-card class="mb-4">
        <v-card-title class="grey lighten-3">
          <v-img alt="" class="shrink mr-2" contain src="/static/risk/ysd7.png" transition="scale-transition"
            max-width="30"></v-img>
          未来7天应收
          <v-spacer></v-spacer>
          {{ notOverdueTotal | formatMoney }}
        </v-card-title>
        <v-card-text>
          <v-list-item v-for="(item, i) in receivableOverdue.ysList" :key="i">
            <v-list-item-content>
              <v-list-item-title>{{ item.FZZ }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="item.notOverdueTotal != null">
              {{ item.notOverdueTotal | formatMoney }}</v-list-item-action>
            <v-list-item-action v-else>-</v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>
    </template>

    <v-dialog v-model="dlgYsdh" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          应收单号
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            {{ overdueItem.distinctFYSDH }}
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">关闭</v-btn>
          <v-btn text color="secondary" @click="copyText">
            <v-fade-transition hide-on-leave>
              <v-icon>{{clicked ? 'done' : 'content_copy'}}</v-icon>
            </v-fade-transition>
            {{clicked ? '已复制' : '复制单号'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogImg"
    >
      <v-card>
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="dialogImg = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-img
          src="/static/risk/overdue.png"
          max-height="80vh"
        ></v-img>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import _ from 'lodash';
import store from "@/store";
import {
  FETCH_RISK_ENTERPRISETYPE_LIST,
  FETCH_ENTERPRISE_LIST,
  FETCH_ENTERPRISE,
  FETCH_RECEIVABLE_OVERDUE,
  FETCH_RECEIVABLE_OVERDUE_SUMMARY,
  FETCH_KINGDEE_CUSTOMER_LIST,
  FETCH_KINGDEE_NAME_SEARCH_LIST,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex';
import Util from "@/common/util";
import moment from "moment";
export default {
  data: () => ({
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      selected: value => {
        if (!value || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
    },
    enterprise: null,
    ysmxCreateTime: null,
    date: moment().format('YYYY-MM-DD HH:mm'),
    startMenu: false,
    cusNameList: [],
    searchCusName: null,
    isCusNameListLoading: false,
    valid: true,
    editedItem: {
      enterpriseName: '',
      isRiskFactor: false,
      riskEnterpriseTypeId: null,
    },
    showResult: false,
    kingdeeCustomerList: [],
    totalCreditLine: 0,
    receivableOverdueSummary: {
      list: [],
    },
    receivableTotal: 0, // 应收合计
    receivableOverdue: {
      ys: [],
      zl: [],
    },
    dlgYsdh: false,
    overdueItem: {},
    clicked: false,
    ysTotal: 0, // 应收超期合计
    zlTotal: 0, // 账龄超期合计
    notOverdueTotal: 0, // 未来7天应收合计
    dialogImg: false,
  }),
  watch: {
    searchCusName: {
      handler: _.debounce(function (val) {
        if (val) {
          if (this.isCusNameListLoading) return;
          this.isCusNameListLoading = true;
          store.dispatch(FETCH_KINGDEE_NAME_SEARCH_LIST, {
            params: {
              key: val,
            }
          })
            .then((data) => {
              this.cusNameList = data.docs.map(n => {
                return {
                  Name: n,
                };
              });
              this.isCusNameListLoading = false;
            })
            .catch((err) => {
              store.commit(SET_ERROR, { msg: err });
              this.isCusNameListLoading = false;
            });
        }
      }, 500)
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'riskEnterpriseTypeList', 'accountList',]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
    cusTypeList () {
      return Util.categories('CusType')
    },
  },
  async mounted() {
    await this.getRiskEnterpriseTypeList();
  },
  async created() {

  },
  methods: {
    async getRiskEnterpriseTypeList() {
      store.dispatch(FETCH_RISK_ENTERPRISETYPE_LIST);
    },
    async submit() {
      if (!this.editedItem.enterpriseName) {
        return;
      }

      this.showResult = true;

      // 查询企业信息
      const enterpirseResult = await store.dispatch(FETCH_ENTERPRISE_LIST, '&key=' + this.editedItem.enterpriseName);
      if (enterpirseResult.enterprise.length > 0) {
        this.enterprise = await this.$store.dispatch(FETCH_ENTERPRISE, enterpirseResult.enterprise[0]._id);
      }

      // 查询应收期超期、账龄超期
      const data = await store.dispatch(FETCH_RECEIVABLE_OVERDUE, {
        params: {
          enterpriseName: this.editedItem.enterpriseName,
        }
      });
      this.receivableOverdue = data;
      this.ysTotal = data.ysList.reduce((acc, n) => acc + n.overdueTotal, 0);
      this.zlTotal = data.zlList.reduce((acc, n) => acc + n.overdueTotal, 0);
      this.notOverdueTotal = data.ysList.reduce((acc, n) => acc + n.notOverdueTotal, 0);

      // 查询已授信总额度
      const kingdeeCustomerListData = await store.dispatch(FETCH_KINGDEE_CUSTOMER_LIST, 'name=' + this.editedItem.enterpriseName);
      this.kingdeeCustomerList = kingdeeCustomerListData.docs;
      this.totalCreditLine = kingdeeCustomerListData.docs.reduce((acc, n) => acc + n.quota, 0);

      // 查询应用总额度，应收明细同步同时
      const dataSummary = await store.dispatch(FETCH_RECEIVABLE_OVERDUE_SUMMARY, {
        params: {
          enterpriseName: this.editedItem.enterpriseName,
        }
      });
      this.receivableOverdueSummary = dataSummary;
      this.receivableTotal = dataSummary.list.reduce((acc, n) => acc + n.total, 0);
      this.ysmxCreateTime = dataSummary.list.length > 0 ? moment(dataSummary.list[0].createTime).format('YYYY-MM-DD HH:mm') : '';
    },
    showDlg(ys) {
      if (!ys.distinctFYSDH) {
        return;
      }

      this.overdueItem = {
        distinctFYSDH: ys.distinctFYSDH.join('，'),
      };
      this.dlgYsdh = true;
    },
    close() {
      this.dlgYsdh = false;
      this.clicked = false;
    },
    copyText() {
      navigator.clipboard.writeText(this.overdueItem.distinctFYSDH)
        .then(() => {
          this.clicked = true
        })
        .catch(() => {
          this.clicked = false
        })
    },
  },
}
</script>
<style scoped>
.v-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
</style>
