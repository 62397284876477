<template>
  <v-container fluid>
    <v-form class="mb-16" v-model="valid" @submit.prevent="submit">
      <v-row>
        <v-col cols="8">
          <div>
            申请人：{{ currentUser.personal.name }}
          </div>
          <div>
            申请时间：{{ date }}
          </div>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.kingdeeOrg"
            @change="getKingdeeCustomer"
            :items="kingdeeOrgList"
            :rules="[rules.selected]"
            item-text="FNAME"
            item-value="_id"
            autocomplete="off"
            label="签约主体*"
            hint="请选择签约主体"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.businessType"
            :items="businessTypeList"
            :rules="[rules.selected]"
            item-text="name"
            item-value="code"
            autocomplete="off"
            label="业务类型*"
            hint="请选择业务类型"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.cusShortName"
            :rules="[rules.required]"
            type="text"
            label="待授信公司简称*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.cusName"
            @change="getKingdeeCustomer"
            :items="cusNameList"
            :loading="isCusNameListLoading"
            :search-input.sync="searchCusName"
            :rules="[rules.required]"
            item-text="Name"
            item-value="Name"
            autocomplete="off"
            label="待授信公司工商全称*"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.riskEnterpriseTypeId"
            :items="riskEnterpriseTypeList"
            :rules="[rules.selected]"
            item-text="name"
            item-value="_id"
            autocomplete="off"
            label="授信资质分类*"
            hint="请选择授信资质分类"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.cusContact"
            :rules="[rules.required]"
            type="text"
            label="联系人*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="editedItem.startDate"
                label="成立日期*"
                readonly
                :rules="[rules.required]"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="editedItem.startDate"
              @input="startMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.registCapiAmt"
            :rules="[rules.required]"
            type="number"
            label="注册资金（RMB）*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.peopleNumber"
            :rules="[rules.required]"
            type="number"
            label="职员人数*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.payCondition"
            :items="payConditionList"
            :rules="[rules.required]"
            item-text="name"
            item-value="code"
            autocomplete="off"
            label="付款条件*"
            hint="请选择付款条件"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
            v-model="editedItem.isNewCus"
            @change="getKingdeeCustomer"
            :items="cusTypeList"
            :rules="[rules.selected]"
            item-text="name"
            item-value="code"
            autocomplete="off"
            label="新/旧客户*"
            hint="请选择新/旧客户"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.oldDays"
            :rules="editedItem.isNewCus ? [] : [rules.required]"
            :disabled="editedItem.isNewCus"
            type="number"
            label="原账期天数*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.oldQuota"
            :rules="editedItem.isNewCus ? [] : [rules.required]"
            :disabled="editedItem.isNewCus"
            type="number"
            label="原额度*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.days"
            :rules="editedItem.payCondition == 1 ? [] : [rules.required]"
            :disabled="editedItem.payCondition == 1"
            type="number"
            label="本次申请账期*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.quota"
            :rules="editedItem.payCondition == 1 ? [] : [rules.required]"
            :disabled="editedItem.payCondition == 1"
            type="number"
            label="本次申请额度*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.invoiceDays"
            :rules="[rules.required]"
            type="number"
            label="开票天数*"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editedItem.cusDesc"
            :rules="[rules.required]"
            auto-grow
            rows="3"
            label="客户基本情况*"
            hint="客户概括/背景"
            value=""
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editedItem.serviceDesc"
            :rules="[rules.required]"
            auto-grow
            rows="3"
            label="业务机会描述*"
            hint="商机概括/规模/盈利水平"
            value=""
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editedItem.enemy"
            :rules="[rules.required]"
            auto-grow
            rows="3"
            label="主要竞争对手*"
            hint="列出至少三个"
            value=""
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="editedItem.finishCus"
            :rules="[rules.required]"
            type="text"
            label="最终用户*"
            hint="若无穿透的底层客户，即：申请授信客户即最终客户，则填写“同授信客户”"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="editedItem.relaCus"
            auto-grow
            rows="3"
            label="关联公司"
            hint="请列举所有会使用的业务主体"
            value=""
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-autocomplete
              v-model="editedItem.cc"
              :items="accountList"
              :rules="[rules.selected]"
              item-text="personal.name"
              item-value="_id"
              autocomplete="off"
              chips
              small-chips
              deletable-chips
              label="授信完成后抄送人员*"
              hint="请查找并选择抄送人员"
              multiple
            >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="mb-4" align="center" justify="center">
        <v-btn color="secondary" @click="preApply" :disabled="!valid">分值测算</v-btn>
      </v-row>
      <v-list-item ripple v-if="showResult">
        <v-list-item-content>
          <v-list-item-subtitle>预授信结果</v-list-item-subtitle>
          <v-list-item-title>得分：{{ preApplyResult.totalScore }}</v-list-item-title>
          <v-list-item-title style="color: green;" v-if="preApplyResult.canSubmit">
            本次授信额度通过
          </v-list-item-title>
          <v-list-item-title v-else style="color: red;">
            本次授信额度未通过
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-row class="my-4" align="center" justify="center" v-if="preApplyResult.canSubmit">
        <v-btn color="secondary" @click="submit" :disabled="!valid">提交授信申请</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import _ from 'lodash'
import store from "@/store";
import { FETCH_KINGDEE_ORG_LIST, FETCH_ACCOUNT_LIST, FETCH_RISK_ENTERPRISETYPE_LIST, FETCH_QCC_NAME_SEARCH_LIST, PUBLISH_RISK_PREAPPROVAL, FETCH_KINGDEE_CUSTOMER, } from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import { mapGetters } from 'vuex';
import Util from "@/common/util";
import moment from "moment";
export default {
  data: () => ({
    rules: {
      required: value => (value === 0 || !!value) || '请输入内容.',
      selected: value => {
        if ((!value && value !== false) || value.length < 1) {
          return '请至少选择一个选项。'
        } else {
          return true;
        }
      },
    },
    kingdeeOrgList: [],
    date: moment().format('YYYY-MM-DD HH:mm'),
    startMenu: false,
    cusNameList: [],
    searchCusName: null,
    isCusNameListLoading: false,
    editedItem: {
      enterpriseId: '',
      kingdeeOrg: '',
      businessType: '',
      cusShortName: '',
      cusName: '',
      cusTypeId: '',
      cusContact: '',
      startDate: '',
      registCapiAmt: '',
      peopleNumber: '',
      payCondition: '',
      isNewCus: null,
      oldDays: '',
      oldQuota: '',
      days: '',
      quota: '',
      invoiceDays: '',
      cusDesc: '',
      serviceDesc: '',
      enemy: '',
      finishCus: '',
      relaCus: '',
      cc: [],
    },
    valid: true,
    showResult: false,
    preApplyResult: {
      totalScore: 0,
      canSubmit: false,
    },
  }),
  watch: {
    searchCusName: {
      handler: _.debounce(function (val) {
        if (val) {
          if (this.isCusNameListLoading) return;
          this.isCusNameListLoading = true;
          store.dispatch(FETCH_QCC_NAME_SEARCH_LIST, {
            params: {
              key: val,
            }
          })
            .then((data) => {
              this.cusNameList = data.docs;
              this.isCusNameListLoading = false;
            })
            .catch((err) => {
              store.commit(SET_ERROR, { msg: err });
              this.isCusNameListLoading = false;
            });
        }
      }, 500)
    },
  },
  computed: {
    ...mapGetters(['currentUser', 'riskEnterpriseTypeList', 'accountList',]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
    cusTypeList () {
      return Util.categories('CusType')
    },
  },
  async mounted() {
    await this.getRiskEnterpriseTypeList();
    const kingdeeOrgListResult = await this.getKingdeeOrgList();
    this.kingdeeOrgList = kingdeeOrgListResult.docs;
  },
  created() {
    this.$store.dispatch(FETCH_ACCOUNT_LIST)
  },
  methods: {
    async getKingdeeOrgList() {
      return store.dispatch(FETCH_KINGDEE_ORG_LIST, 'limit=-1');
    },
    async getRiskEnterpriseTypeList() {
      store.dispatch(FETCH_RISK_ENTERPRISETYPE_LIST);
    },
    async getKingdeeCustomer() {
      if(this.editedItem.kingdeeOrg && this.editedItem.isNewCus === false && this.editedItem.cusName) {
        const data = await store.dispatch(FETCH_KINGDEE_CUSTOMER, {
          params: {
            kingdeeOrg: this.editedItem.kingdeeOrg,
            customerName: this.editedItem.cusName,
          }
        });
        
        if (Array.isArray(data)) {
          if (data[0]) {
            this.editedItem.oldDays = data[0];
          }
          if (data[1]) {
            this.editedItem.oldQuota = data[1];
          }
          if (data[2]) {
            this.editedItem.invoiceDays = data[2];
          }
        }
      }
    },
    preApply() {
      let riskApproval = {
        enterpriseId: this.editedItem.enterpriseId,
        kingdeeOrg: this.editedItem.kingdeeOrg,
        businessType: this.editedItem.businessType,
        cusShortName: this.editedItem.cusShortName,
        cusName: this.editedItem.cusName,
        riskEnterpriseTypeId: this.editedItem.riskEnterpriseTypeId,
        cusContact: this.editedItem.cusContact,
        startDate: this.editedItem.startDate,
        registCapiAmt: parseInt(this.editedItem.registCapiAmt),
        peopleNumber: parseInt(this.editedItem.peopleNumber),
        payCondition: this.editedItem.payCondition,
        isNewCus: this.editedItem.isNewCus,
        oldDays: parseInt(this.editedItem.oldDays),
        oldQuota: parseFloat(this.editedItem.oldQuota),
        days: parseInt(this.editedItem.days),
        quota: parseFloat(this.editedItem.quota),
        invoiceDays: parseFloat(this.editedItem.invoiceDays),
        cusDesc: this.editedItem.cusDesc,
        serviceDesc: this.editedItem.serviceDesc,
        enemy: this.editedItem.enemy,
        finishCus: this.editedItem.finishCus,
        relaCus: this.editedItem.relaCus,
        cc: this.editedItem.cc,
      };

      this.$store.dispatch(PUBLISH_RISK_PREAPPROVAL, riskApproval)
        .then((data) => {
          this.showResult = true;
          this.preApplyResult = data.data;
        })
        .catch((err) => {
          this.$store.commit(SET_ERROR, { msg: err.response.data.message });
        });
    },
    submit () {
      let riskApproval = {
        enterpriseId: this.editedItem.enterpriseId,
        kingdeeOrg: this.editedItem.kingdeeOrg,
        businessType: this.editedItem.businessType,
        cusShortName: this.editedItem.cusShortName,
        cusName: this.editedItem.cusName,
        riskEnterpriseTypeId: this.editedItem.riskEnterpriseTypeId,
        cusContact: this.editedItem.cusContact,
        startDate: this.editedItem.startDate,
        registCapiAmt: parseInt(this.editedItem.registCapiAmt),
        peopleNumber: parseInt(this.editedItem.peopleNumber),
        payCondition: this.editedItem.payCondition,
        isNewCus: this.editedItem.isNewCus,
        oldDays: parseInt(this.editedItem.oldDays),
        oldQuota: parseFloat(this.editedItem.oldQuota),
        days: parseInt(this.editedItem.days),
        quota: parseFloat(this.editedItem.quota),
        invoiceDays: parseFloat(this.editedItem.invoiceDays),
        cusDesc: this.editedItem.cusDesc,
        serviceDesc: this.editedItem.serviceDesc,
        enemy: this.editedItem.enemy,
        finishCus: this.editedItem.finishCus,
        relaCus: this.editedItem.relaCus,
        cc: this.editedItem.cc,
      };

      localStorage.setItem('creditlineApply', JSON.stringify(riskApproval));

      this.$router.push({ path: '/dealer/creditline/apply' });
    },
    doAction(action) {
      switch (action) {
        case 'set_start_date': {
          this.startDate = false
          if (this.editedItem.warranty_time && this.editedItem.startDate) {
            let startDate = moment(this.editedItem.startDate)
            startDate = startDate.format('YYYY-MM-DD')
            let warranty_deadline = moment(startDate).add(this.editedItem.editedItem, 'M')
            warranty_deadline = moment(warranty_deadline).subtract(1, 'd')
            warranty_deadline = moment(warranty_deadline).format('YYYY-MM-DD')
            this.editedItem.warranty_deadline = warranty_deadline
            this.$forceUpdate()
          }
          break
        }
      }
    },
  },
}
</script>
<style scoped>

</style>

