var render = function () {
  var _vm$approvalStatusLis, _vm$riskApproval$crea, _vm$riskApproval$crea2, _vm$riskApproval$ente, _vm$riskApproval$king, _vm$businessTypeList$, _vm$riskApproval$risk, _vm$payConditionList$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "flat": "",
      "dark": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("企业授信申请详情")])], 1), _c('v-card-text', [_c('v-subheader', [_vm._v("授信申请（" + _vm._s((_vm$approvalStatusLis = _vm.approvalStatusList.find(function (n) {
    return n.code == _vm.riskApproval.instanceStatus;
  })) === null || _vm$approvalStatusLis === void 0 ? void 0 : _vm$approvalStatusLis.name) + "）")]), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 流水号 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.serialNumber) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 申请人 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$riskApproval$crea = _vm.riskApproval.creator) === null || _vm$riskApproval$crea === void 0 ? void 0 : (_vm$riskApproval$crea2 = _vm$riskApproval$crea.personal) === null || _vm$riskApproval$crea2 === void 0 ? void 0 : _vm$riskApproval$crea2.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 申请时间 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatTime")(_vm.riskApproval.createTime)) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 客户名称 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$riskApproval$ente = _vm.riskApproval.enterprise) === null || _vm$riskApproval$ente === void 0 ? void 0 : _vm$riskApproval$ente.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 签约主体 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$riskApproval$king = _vm.riskApproval.kingdeeOrg) === null || _vm$riskApproval$king === void 0 ? void 0 : _vm$riskApproval$king.FNAME) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 业务类型 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$businessTypeList$ = _vm.businessTypeList.find(function (n) {
    return n.code === _vm.riskApproval.businessType;
  })) === null || _vm$businessTypeList$ === void 0 ? void 0 : _vm$businessTypeList$.name) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 待授信公司简称 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.cusShortName) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 授信资质分类 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$riskApproval$risk = _vm.riskApproval.riskEnterpriseType) === null || _vm$riskApproval$risk === void 0 ? void 0 : _vm$riskApproval$risk.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 联系人 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.cusContact) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 成立时间 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatTime")(_vm.riskApproval.startDate)) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 注册资金（RMB） ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.riskApproval.registCapiAmt)) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 职员人数 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.peopleNumber) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 付款条件 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s((_vm$payConditionList$ = _vm.payConditionList.find(function (n) {
    return n.code.toString() === _vm.riskApproval.payCondition;
  })) === null || _vm$payConditionList$ === void 0 ? void 0 : _vm$payConditionList$.name) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 新/旧客户 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.isNewCus ? '新' : '旧') + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 原账期天数 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.oldDays ? _vm.riskApproval.oldDays : '-') + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 原额度 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.oldQuota ? _vm.riskApproval.oldQuota | _vm.formatMoney : '-') + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 本次申请账期 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.days) + " ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 本次申请额度 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm._f("formatMoney")(_vm.riskApproval.quota)) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 开票天数 ")]), _c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.invoiceDays) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 客户基本情况 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.cusDesc) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 业务机会描述 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.serviceDesc) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 主要竞争对手 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.enemy) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 最终用户 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.finishCus) + " ")])], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "2"
    }
  }, [_vm._v(" 关联公司 ")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(" " + _vm._s(_vm.riskApproval.relaCus) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }