var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "max-width": "90%",
      "outlined": ""
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/static/system/rent.png",
      "height": "200px",
      "contain": ""
    }
  }), _c('v-card-title', [_vm._v(" ESP风控系统移动端 ")]), _c('v-card-subtitle', [_vm._v(" ESP风控系统移动端是为倍升公司内部销售团队提供的授信申请工具，该工具需要在飞书内运行，同时需要在ESP后台开通相应权限后即可使用。如在此页面底部未提供功能入口，请联系风控小组获取权限。 ")]), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_vm._v(" 查看您的权限 ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [_c('v-icon', [_vm._v(_vm._s(_vm.show ? 'keyboard_arrow_up' : 'keyboard_arrow_down'))])], 1)], 1), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }]
  }, [_c('v-divider'), _c('v-card-text', [_c('v-list', [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("实地尽调")])], 1), _c('v-list-item-icon', [this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-icon', {
    attrs: {
      "color": "green"
    }
  }, [_vm._v(" check_circle ")]) : _c('v-icon', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v(" block ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("授信申请")])], 1), _c('v-list-item-icon', [this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-icon', {
    attrs: {
      "color": "green"
    }
  }, [_vm._v(" check_circle ")]) : _c('v-icon', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v(" block ")])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("授信审批")])], 1), _c('v-list-item-icon', [this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskAdmin') ? _c('v-icon', {
    attrs: {
      "color": "green"
    }
  }, [_vm._v(" check_circle ")]) : _c('v-icon', {
    attrs: {
      "color": "red"
    }
  }, [_vm._v(" block ")])], 1)], 1)], 1)], 1)], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }