<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>授信申请</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-autocomplete
          autocomplete="off"
          v-model="query.enterprise"
          :items="monthlyEnterpriseList"
          @change="doAction('search_enterprise')"
          label="待授信公司工商全称"
          hint="请选择一家企业"
          item-text="name"
          item-value="_id"
          outlined
          flat
          dense
          dark
          hide-details
          clearable
          class="mx-4"
          prepend-inner-icon="search"
          solo-inverted
        ></v-autocomplete>
      </v-toolbar>
      <v-card-text>
        <!-- <v-alert dense type="info" class="ma-2" dismissible>
          企业报表是为企业提供月度统计信息的报告。只有经过经销商人员编辑后且发布的报告才会在企业端呈现。
          <span class="font-weight-bold">月度报告每个月第一天0点系统自动生成</span>
        </v-alert> -->
        <v-form v-model="valid" @submit.prevent="submit">
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.kingdeeOrg"
                :items="kingdeeOrgList"
                :rules="[rules.selected]"
                item-text="name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="签约主体*"
                hint="请选择签约主体"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.businessType"
                :items="businessTypeList"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                autocomplete="off"
                outlined
                label="业务类型*"
                hint="请选择业务类型"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.cusShortName"
                :rules="[rules.required]"
                type="text"
                label="待授信公司简称*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.riskEnterpriseTypeId"
                :items="riskEnterpriseTypeList"
                :rules="[rules.selected]"
                item-text="name"
                item-value="_id"
                autocomplete="off"
                outlined
                label="授信资质分类*"
                hint="请选择授信资质分类"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.cusContact"
                :rules="[rules.required]"
                type="text"
                label="联系人*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-menu
                v-model="startMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.startDate"
                    label="成立日期*"
                    readonly
                    :rules="[rules.required]"
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedItem.startDate"
                  @input="startMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.registCapiAmt"
                :rules="[rules.required]"
                type="number"
                label="注册资金（RMB）*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.peopleNumber"
                :rules="[rules.required]"
                type="number"
                label="职员人数*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.payCondition"
                :items="payConditionList"
                :rules="[rules.required]"
                item-text="name"
                item-value="code"
                autocomplete="off"
                outlined
                label="付款条件*"
                hint="请选择付款条件"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                v-model="editedItem.isNewCus"
                :items="cusTypeList"
                :rules="[rules.selected]"
                item-text="name"
                item-value="code"
                autocomplete="off"
                outlined
                label="新/旧客户*"
                hint="请选择新/旧客户"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.oldDays"
                :rules="editedItem.isNewCus ? [] : [rules.required]"
                :disabled="editedItem.isNewCus"
                type="number"
                label="原账期天数*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.oldQuota"
                :rules="editedItem.isNewCus ? [] : [rules.required]"
                :disabled="editedItem.isNewCus"
                type="number"
                label="原额度*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.days"
                :rules="editedItem.payCondition == 1 ? [] : [rules.required]"
                :disabled="editedItem.payCondition == 1"
                type="number"
                label="本次申请账期*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.quota"
                :rules="editedItem.payCondition == 1 ? [] : [rules.required]"
                :disabled="editedItem.payCondition == 1"
                type="number"
                label="本次申请额度*"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.cusDesc"
                :rules="[rules.required]"
                outlined
                auto-grow
                rows="3"
                label="客户基本情况*"
                hint="客户概括/背景"
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.serviceDesc"
                :rules="[rules.required]"
                outlined
                auto-grow
                rows="3"
                label="业务机会描述*"
                hint="商机概括/规模/盈利水平"
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.enemy"
                :rules="[rules.required]"
                outlined
                auto-grow
                rows="3"
                label="主要竞争对手*"
                hint="列出至少三个"
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="editedItem.finishCus"
                :rules="[rules.required]"
                type="text"
                label="最终用户*"
                hint="若无穿透的底层客户，即：申请授信客户即最终客户，则填写“同授信客户”"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="editedItem.relaCus"
                outlined
                auto-grow
                rows="3"
                label="关联公司"
                hint="请列举所有会使用的业务主体"
                value=""
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                  v-model="editedItem.cc"
                  :items="accountList"
                  :rules="[rules.selected]"
                  item-text="personal.name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  chips
                  small-chips
                  deletable-chips
                  label="授信完成后抄送人员*"
                  hint="请查找并选择抄送人员"
                  multiple
                >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" depressed @click="submit" :disabled="!valid">提交</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import moment from 'moment';
import Util from '@/common/util';
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_RISK_APPROVAL_LIST,
  FETCH_QCC_NAME_SEARCH_LIST
} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      query: {
        enterprise: '',
      },
      defaultQuery: {
        enterprise: '',
      },
      list_options: {},
      listHeader: [
        { text: '流水号', value: 'serialNumber' },
        { text: '企业名称', value: 'enterprise.name' },
        { text: '付款条件', value: 'payCondition' },
        { text: '申请账期（天）', value: 'days' },
        { text: '申请额度（元）', value: 'quota' },
        { text: '当前审批节点', value: 'taskNode' },
        { text: '状态', value: 'instanceStatus' },
        { text: '申请时间', value: 'createTime' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
      },
      kingdeeOrgList: [],
      date: moment().format('YYYY-MM-DD HH:mm'),
      startMenu: false,
      cusNameList: [],
      searchCusName: null,
      isCusNameListLoading: false,
      dlgInfo: false,
      activeType: '',
      activeId: null,
      editedItem: {
        enterpriseId: '',
        kingdeeOrg: '',
        businessType: '',
        cusShortName: '',
        cusName: '',
        cusTypeId: '',
        atchCus: null,
        cusContact: '',
        startDate: '',
        registCapiAmt: '',
        peopleNumber: '',
        payCondition: '',
        isNewCus: null,
        oldDays: '',
        oldQuota: '',
        days: '',
        quota: '',
        cusDesc: '',
        serviceDesc: '',
        enemy: '',
        finishCus: '',
        relaCus: '',
        cc: [],
      },
      valid: true,
    };
  },
  created() {
    this.getRiskApprovalList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "riskApprovalLoading",
      "riskApprovalList",
      "riskApprovalCount",
      "monthlyEnterpriseList",
    ]),
    payConditionList () {
      return Util.categories('PayCondition')
    },
    approvalStatusList () {
      return Util.categories('ApprovalStatus')
    },
  },
  watch: {
    searchCusName(val) {
      if (this.countDown) {
        clearTimeout(this.countDown);
      }
      this.countDown = setTimeout(() => {
        if (this.isCusNameListLoading) return;
        this.isCusNameListLoading = true;
        store
          .dispatch(FETCH_QCC_NAME_SEARCH_LIST, {
            params: {
              key: val,
            }
          })
          .then((data) => {
            this.cusNameList = data.docs;
            this.isCusNameListLoading = false;
          })
          .catch((err) => {
            store.commit(SET_ERROR, { msg: err });
            this.isCusNameListLoading = false;
          });
      }, 500);
    },
  },
  mounted() {},
  methods: {
    getRiskApprovalList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.list_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (Object.keys(this.list_options).length > 0) {
        store.dispatch(FETCH_RISK_APPROVAL_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'search_enterprise': {
          if (this.query.enterprise !== '') {
            this.getRiskApprovalList({enterprise: this.query.enterprise})
          } else {
            this.getRiskApprovalList()
          }
          break
        }
        case 'open_enterprise': {
          this.activeType = 'enterprise'
          this.activeId = item._id
          this.dlgInfo = true
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
      }
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
