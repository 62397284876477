var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "secondary",
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("授信申请")]), _c('v-spacer'), _c('v-autocomplete', {
    staticClass: "mx-4",
    attrs: {
      "autocomplete": "off",
      "items": _vm.monthlyEnterpriseList,
      "label": "待授信公司工商全称",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "flat": "",
      "dense": "",
      "dark": "",
      "hide-details": "",
      "clearable": "",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('search_enterprise');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  })], 1), _c('v-card-text', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.kingdeeOrgList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "签约主体*",
      "hint": "请选择签约主体"
    },
    model: {
      value: _vm.editedItem.kingdeeOrg,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "kingdeeOrg", $$v);
      },
      expression: "editedItem.kingdeeOrg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.businessTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "业务类型*",
      "hint": "请选择业务类型"
    },
    model: {
      value: _vm.editedItem.businessType,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "businessType", $$v);
      },
      expression: "editedItem.businessType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "待授信公司简称*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.cusShortName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusShortName", $$v);
      },
      expression: "editedItem.cusShortName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.riskEnterpriseTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "label": "授信资质分类*",
      "hint": "请选择授信资质分类"
    },
    model: {
      value: _vm.editedItem.riskEnterpriseTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "riskEnterpriseTypeId", $$v);
      },
      expression: "editedItem.riskEnterpriseTypeId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系人*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.cusContact,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusContact", $$v);
      },
      expression: "editedItem.cusContact"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "成立日期*",
            "readonly": "",
            "rules": [_vm.rules.required],
            "outlined": ""
          },
          model: {
            value: _vm.editedItem.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "startDate", $$v);
            },
            expression: "editedItem.startDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.startMenu,
      callback: function callback($$v) {
        _vm.startMenu = $$v;
      },
      expression: "startMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.startMenu = false;
      }
    },
    model: {
      value: _vm.editedItem.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "startDate", $$v);
      },
      expression: "editedItem.startDate"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "注册资金（RMB）*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.registCapiAmt,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "registCapiAmt", $$v);
      },
      expression: "editedItem.registCapiAmt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "职员人数*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.peopleNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "peopleNumber", $$v);
      },
      expression: "editedItem.peopleNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.payConditionList,
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "付款条件*",
      "hint": "请选择付款条件"
    },
    model: {
      value: _vm.editedItem.payCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "payCondition", $$v);
      },
      expression: "editedItem.payCondition"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cusTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "label": "新/旧客户*",
      "hint": "请选择新/旧客户"
    },
    model: {
      value: _vm.editedItem.isNewCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isNewCus", $$v);
      },
      expression: "editedItem.isNewCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.isNewCus ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.isNewCus,
      "type": "number",
      "label": "原账期天数*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.oldDays,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "oldDays", $$v);
      },
      expression: "editedItem.oldDays"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.isNewCus ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.isNewCus,
      "type": "number",
      "label": "原额度*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.oldQuota,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "oldQuota", $$v);
      },
      expression: "editedItem.oldQuota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.payCondition == 1,
      "type": "number",
      "label": "本次申请账期*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.days,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "days", $$v);
      },
      expression: "editedItem.days"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.payCondition == 1,
      "type": "number",
      "label": "本次申请额度*",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.quota,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "quota", $$v);
      },
      expression: "editedItem.quota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "客户基本情况*",
      "hint": "客户概括/背景",
      "value": ""
    },
    model: {
      value: _vm.editedItem.cusDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusDesc", $$v);
      },
      expression: "editedItem.cusDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "业务机会描述*",
      "hint": "商机概括/规模/盈利水平",
      "value": ""
    },
    model: {
      value: _vm.editedItem.serviceDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "serviceDesc", $$v);
      },
      expression: "editedItem.serviceDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "主要竞争对手*",
      "hint": "列出至少三个",
      "value": ""
    },
    model: {
      value: _vm.editedItem.enemy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enemy", $$v);
      },
      expression: "editedItem.enemy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "最终用户*",
      "hint": "若无穿透的底层客户，即：申请授信客户即最终客户，则填写“同授信客户”",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.finishCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "finishCus", $$v);
      },
      expression: "editedItem.finishCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "auto-grow": "",
      "rows": "3",
      "label": "关联公司",
      "hint": "请列举所有会使用的业务主体",
      "value": ""
    },
    model: {
      value: _vm.editedItem.relaCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "relaCus", $$v);
      },
      expression: "editedItem.relaCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "rules": [_vm.rules.selected],
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "授信完成后抄送人员*",
      "hint": "请查找并选择抄送人员",
      "multiple": ""
    },
    model: {
      value: _vm.editedItem.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cc", $$v);
      },
      expression: "editedItem.cc"
    }
  })], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "depressed": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")]), _c('v-spacer')], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_vm.activeType === 'enterprise' ? _c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": function Close($event) {
        return _vm.doAction('close_info');
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }