<template>
  <div class="fluid">
    <v-data-table :headers="headerTable" :items="entGroupList" :options.sync="optionsTable"
      :server-items-length="entGroupCount" :loading="entGroupLoading" loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" fixed-header height="600" item-key="_id"
      transition>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>集团列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="query.key" label="关键字搜索（按回车）" prepend-inner-icon="search"
            :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'" solo flat dense hide-details clearable
            @keydown.enter="searchItem" @click:clear="clearSearchItem"></v-text-field>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ma-2" depressed color="secondary" @click="editItem()">
                <v-icon>add</v-icon> 创建集团
              </v-btn>
            </template>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                <v-icon small>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.isInLitigation="{ item }">
        <td nowrap="true">{{ item.isInLitigation ? '已涉诉' : '未涉诉' }}</td>
      </template>
      <template v-slot:item.updateTime="{ item }">
        <td nowrap="true">{{item.updateTime | formatTime}}</td>
      </template>
      <template v-slot:item.actions="{ item }">
        <td nowrap="true">
          <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon small @click="deleteItem(item)">delete</v-icon>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">是否涉诉</h2>
        <v-chip-group v-model="query.isInLitigation" column multiple>
          <v-chip :value="false" filter label outlined>未涉诉</v-chip>
          <v-chip :value="true" filter label outlined>已涉诉</v-chip>
        </v-chip-group>
      </v-card-text>
    </v-navigation-drawer>
    <v-dialog v-model="dlgEdit" persistent max-width="600px">
      <v-form v-model="valid" @submit.prevent="submit">
        <v-card>
          <v-card-title>
            <span class="headline">{{dlgTitle}}</span>
          </v-card-title>
          <v-card-text>
            <v-text-field v-model="editedItem.name" :rules="[rules.required]" type="text" label="集团名称"
              hint="请输入集团的名称，该名称为全局唯一命名" outlined dense></v-text-field>
            <v-text-field v-model="editedItem.invoiceDays" :rules="[rules.required]" type="number" label="开票天数"
              hint="请输入开票天数" outlined dense></v-text-field>
            <v-text-field v-model="editedItem.creditLineMax" type="number" label="集团授信额度峰值（元）"
              hint="请输入集团授信额度峰值" outlined dense></v-text-field>
            <v-radio-group v-model="editedItem.isInLitigation" row>
              <v-radio label="未涉诉" :value="false"></v-radio>
              <v-radio label="已涉诉" :value="true"></v-radio>
            </v-radio-group>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close()" v-if="$route.params.id !== '0'">取消</v-btn>
            <v-btn color="secondary" text @click="submit" :disabled="!valid">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {
  PUBLISH_ENTGROUP,
  EDIT_ENTGROUP,
  FETCH_ENTGROUP_LIST,
  FETCH_ENTGROUP,
  DELETE_ENTGROUP,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        isInLitigation: false,
      },
      defaultItem: {
        isInLitigation: false,
      },
      filterDrawer: false,
      query: {
        key: '',
        year: null,
        city: [],
      },
      defaultQuery: {
        key: '',
        year: null,
        city: [],
      },
      headerTable: [
        {text: '集团名称', width: '250px', value: 'name'},
        {text: '开票天数', width: '125px', value: 'invoiceDays'},
        {text: '集团授信额度峰值（元）', width: '125px', value: 'creditLineMax'},
        {text: '是否涉诉', width: '125px', value: 'isInLitigation'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      dlgInfo: false,
      activeType: 'import',
      importExcelNotice: [['请使用此模版批量导入风控经济数据，*为必填项目。']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false,
      exportEnd: true,
    }
  },
  async created() {
    await this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "entGroupLoading",
      "entGroupList",
      "entGroupCount"]),
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    doAction(action) {
      switch (action) {
        case 'create_item': {
          this.$router.push({path:'/dealer/system/risk/model/edit/0'})
          break
        }
      }
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    async getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      urlQuery += '&type=1';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.year) urlQuery += '&year=' + this.query.year
      if (this.query.city.length) urlQuery += '&city='+ encodeURIComponent(this.query.city)
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_ENTGROUP_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    async deleteItem(item) {
      if (confirm(`确定要 删除 ${item.name}？`)) {
        this.$store.dispatch(DELETE_ENTGROUP, item)
          .then(() => {
            this.getDataList()
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      }
    },
    closeItem() {
      this.dlgInfo = false
    },
    editItem(item) {
      if (item) {
        store.dispatch(FETCH_ENTGROUP, item._id)
          .then((data) => {
            console.log(data)
            this.editedItem = Object.assign({}, data);
          })
      } else {
        this.editedItem = Object.assign({}, this.defaultItem)
      }
      this.dlgTitle = item ? '编辑集团信息' : '创建新集团'
      this.dlgEdit = true

    },
    async submit() {
      this.editedItem.invoiceDays = parseInt(this.editedItem.invoiceDays);
      this.editedItem.creditLineMax = parseFloat(this.editedItem.creditLineMax);
      if (this.editedItem._id) {
        store.dispatch(EDIT_ENTGROUP, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, { msg: '修改成功', color: 'primary' });
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          })
      } else {
        store.dispatch(PUBLISH_ENTGROUP, this.editedItem)
          .then(() => {
            store.commit(SET_ERROR, { msg: '创建成功', color: 'primary' });
            this.query.key = ''
            this.query.filterItem = []
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, { msg: error.response.data.message });
          })
      }
    },
    close () {
      this.dlgTitle = '';
      this.dlgEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    importItem() {
      this.activeType = 'import'
      this.dlgInfo = true
    },
    exportItem(){
      if (this.exportEnd) {
        this.exportFile(this.query)
      }
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

