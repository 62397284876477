<template>
  <v-container>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-btn icon @click="$router.push('/dealer/workbench')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>订单服务列表</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_item')"
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索订单编号、PO号、DT号"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <v-alert dense type="info" class="ma-2" dismissible>
        订单服务是经销商为企业提供采购和租赁订单的处理模块，通过订单可以推送相关任务工单和外采工单。
      </v-alert>
      <v-data-table
        :options.sync="optionsItem"
        :headers="computedHeaders"
        :items="orderPurchaseList"
        :server-items-length="orderPurchaseCount"
        :loading="orderPurchaseLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
        class="elevation-0 mt-4"
        transition
        fixed-header
        height="600"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tabs align-with-title v-model="active_tab">
              <v-tabs-slider color="secondary"></v-tabs-slider>
              <v-tab @click="todoList('all')">全部订单</v-tab>
              <v-tab @click="todoList('purchase')">采购订单</v-tab>
              <v-tab @click="todoList('rental')">租赁订单</v-tab>
              <v-tab @click="todoList('follow')">我的关注</v-tab>
            </v-tabs>
            <v-menu offset-y v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Order_Service') || currentUser.permission.includes('Order_Create')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="secondary" depressed>
                  <v-icon>add</v-icon>
                  新建订单
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="openNewOrder('purchase')">
                  <v-list-item-icon>
                    <v-icon>shopping_cart</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>采购订单</v-list-item-title>
                </v-list-item>
                <v-list-item @click="openNewOrder('rent')">
                  <v-list-item-icon>
                    <v-icon>compare_arrows</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>租赁订单</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom v-if="currentUser.hasAdmin.includes('dealer') || currentUser.permission.includes('Order_Service') || currentUser.permission.includes('Order_Create')">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('export_item')" :loading="loadingDownload">
                  <v-icon small>save_alt</v-icon>
                </v-btn>
              </template>
              <span>导出数据</span>
            </v-tooltip>
            <widgets-custom-header :defaultHeader="defaultHeader" :headerList="headerItem" :version=2 tableName="orderHeader" v-on:Cancel="doAction('cancel_header')" v-on:Default="doAction('default_header')" v-on:Save="doAction('set_header')"></widgets-custom-header>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="filterDrawer = !filterDrawer">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('reload_item')">
                  <v-icon small>autorenew</v-icon>
                </v-btn>
              </template>
              <span>刷新列表</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.order_number="{ item }">
          <td nowrap="true" class="secondary--text align-center">
            <v-btn icon color="secondary" v-if="item.isMark" @click="markOrder(item._id, false)"><v-icon transition="scale-transition">star</v-icon></v-btn>
            <v-btn icon v-else @click="markOrder(item._id, true)"><v-icon transition="scale-transition">star_outline</v-icon></v-btn>
            <v-badge color="secondary" dot :value="false">
              <widgets-order-dialogs :getId="item._id" :key="item._id" v-on:Update="updateList">
                {{ item.order_number }}
              </widgets-order-dialogs>
            </v-badge>
            <!-- <v-icon small class="mx-2" v-if="index === 0">new_releases</v-icon> -->
          </td>
        </template>
        <template v-slot:item.product_list="{ item }">
          <td class="v-list-item--link" @click="productDlg = true">
            {{ item.product_list }}
          </td>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip label small color="secondary" v-if="item.status == '0'">已接单</v-chip>
          <v-chip label small color="primary" v-if="item.status == '1'">处理中</v-chip>
          <v-chip label small v-if="item.status == '2'">已取消</v-chip>
          <v-chip label small v-if="item.status == '3'">已拒绝</v-chip>
          <v-chip label small v-if="item.status == '4'">已完成</v-chip>
        </template>
        <template v-slot:item.createTime="{ item }">
          <td nowrap="true">{{ item.createTime | formatTime }}</td>
        </template>
        <template v-slot:item.updateTime="{ item }">
          <td nowrap="true">{{ item.updateTime | formatTime }}</td>
        </template>
        <template v-slot:item.count_case="{ item }">
          <td nowrap="true" class="v-list-item--link" @click="caseDlg = true">{{ item.count_case }}个</td>
        </template>
        <template v-slot:item.count_asset="{ item }">
          <td nowrap="true">{{ item.count_asset }}台</td>
        </template>
        <template v-slot:item.count_product="{ item }">
          <!-- ipad pro - 0/10台
          <br>
          iphone 12 pro - 11/20台 -->
          {{ item.count_product }}种
        </template>
        <template v-slot:item.product_part_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_part_list" :key="i">
              <v-chip label small style="width: 200px;">
                    <span
                      class="text-truncate"
                      style="max-width: 200px;"
                    >
                      {{ pn || '无'}}
                    </span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_name_list="{ item }">
          <td nowrap="true">
            <div class="my-2" v-for="(pn,i) in item.product_name_list" :key="i">
              <v-chip label small style="width: 200px;">
                    <span
                      class="text-truncate"
                      style="max-width: 200px;"
                    >
                      {{ pn }}
                    </span>
              </v-chip>
            </div>
          </td>
        </template>
        <template v-slot:item.product_quantity_list="{ item }">

          <div class="my-2" v-for="(pn,i) in item.product_quantity_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
            </v-chip>
          </div>

        </template>
        <template v-slot:item.product_asset_list="{ item }">

          <div class="my-2" v-for="(pn,i) in item.product_asset_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
            </v-chip>
          </div>

        </template>
        <template v-slot:item.product_await_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_await_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_cancel_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_cancel_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn || 0 }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.product_cases_list="{ item }">
          <div class="my-2" v-for="(pn,i) in item.product_cases_list" :key="i">
            <v-chip label small>
              <span style="min-width:40px;" class="text-center">{{ pn || 0 }}</span>
            </v-chip>
          </div>
        </template>
        <template v-slot:item.enterprise="{ item }">
          <td nowrap="true" v-if="item.enterprise"
              width="200"
              class="v-list-item--link text-truncate"
              @click="openEnterInfo(item.enterprise._id)"
          >
            {{ item.enterprise.name }}
          </td>
        </template>
        <template v-slot:item.sales="{ item }">
          <td nowrap="true" v-if="item.sales">{{ item.sales.personal ? item.sales.personal.name : item.sales.username }}</td>
        </template>
        <template v-slot:item.editor="{ item }">
          <td nowrap="true" v-if="item.editor && item.editor.personal">{{ item.editor.personal.name }}</td>
        </template>
        <template v-slot:item.creator="{ item }">
          <td nowrap="true" v-if="item.creator && item.creator.personal">{{ item.creator.personal.name }}</td>
        </template>
        <template v-slot:item.total_price="{ item }">
          <td nowrap="true">¥ {{ item.total_price.$numberDecimal | formatMoney }}</td>
        </template>
        <template v-slot:item.total_quantity="{ item }">
          {{ item.total_quantity }}台
        </template>
        <template v-slot:item.left_quantity="{ item }">
          <td nowrap="true">{{ item.total_quantity - item.count_asset }}台</td>
        </template>
        <template v-slot:item.payment_day="{ item }">
          {{ item.payment_day }}
        </template>
        <template v-slot:item.rent_info.rent_type="{ item }">
          <div v-if="item.rent_info">
            {{ item.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁' }}
          </div>
          <div v-else>
            无
          </div>
        </template>
        <template v-slot:item.rent_info.lease_start="{ item }">
              <span v-if="item.rent_info">
                {{ item.rent_info.lease_start | formatDate }}
              </span>
        </template>
        <template v-slot:item.rent_info.lease_term="{ item }">
              <span v-if="item.rent_info">
                {{ item.rent_info.lease_term / 365 }}年
              </span>
        </template>
        <template v-slot:item.rent_info.payment_cycle="{ item }">
              <span v-if="item.rent_info">
                <span v-if="item.rent_info.payment_cycle === 0">
                  月付
                </span>
                <span v-else-if="item.rent_info.payment_cycle === 1">
                  季付
                </span>
                <span v-else>
                  半年付
                </span>
              </span>
        </template>
        <template v-slot:item.rent_info.payment_period="{ item }">
              <span v-if="item.rent_info">
                {{ item.rent_info.payment_period }}天
              </span>
        </template>
        <template v-slot:item.rent_info.total_period="{ item }">
              <span v-if="item.rent_info">
                {{ item.rent_info.total_period }}期
              </span>
        </template>
        <template v-slot:item.rent_info.expiry_date="{ item }">
              <span v-if="item.rent_info">
                {{ item.rent_info.expiry_date | formatDate }}
              </span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
                small
              >
                <v-icon small>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item class="v-list-item&#45;&#45;link" @click="copyOrder(item._id, item.type)">
                <v-list-item-icon>
                  <v-icon small>content_copy</v-icon>
                </v-list-item-icon>
                <v-list-item-title> 复制 </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.type === 1" class="v-list-item&#45;&#45;link" @click="leaseOrder(item._id)">
                <v-list-item-icon>
                  <v-icon small>autorenew</v-icon>
                </v-list-item-icon>
                <v-list-item-title> 续租 </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="newOrderDlg" width="600">
      <v-card>
        <v-card-title>选择企业</v-card-title>
        <v-card-text>
          <v-autocomplete
            autocomplete="off"
            v-model="orderItem.enterprise"
            :items="dealer_enterprise"
            :rules="[rules.selected]"
            label="企业"
            hint="请选择一家企业"
            item-text="name"
            item-value="_id"
            outlined
            dense
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="newOrderDlg = false">取消</v-btn>
            <v-btn color="secondary" text :disabled="!valid" @click="openNewOrder">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="productDlg" width="600">
      <v-card>
        <v-card-title>型号列表</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-12">
          <v-data-table
            :headers="proHeaders"
            :items="prodcutList"
            hide-default-footer
            dense
          >
            <template v-slot:body.append>
              <tr class="grey lighten-3 secondary--text">
                <td>合计</td>
                <td>10</td>
                <td></td>
                <td>¥114995</td>
              </tr>
            </template>
            <template v-slot:item.price="{ item }">
              <td nowrap="true">¥{{ item.price }}</td>
            </template>
            <template v-slot:item.price_total="{ item }">
              <td nowrap="true">¥{{ item.price_total }}</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="productDlg = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="caseDlg" width="900">
      <v-card>
        <v-card-title>工单列表</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-12">
          <v-data-table
            :headers="caseHeaders"
            :items="caseList"
            hide-default-footer
            dense
          >
            <template v-slot:item.price="{ item }">
              <td nowrap="true">¥{{ item.price }}</td>
            </template>
            <template v-slot:item.price_total="{ item }">
              <td nowrap="true">¥{{ item.price_total }}</td>
            </template>
            <template v-slot:item.status="{ item }">
              <td nowrap="true">
                <v-chip label small color="primary">
                  {{ item.status }}
                </v-chip>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn text @click="caseDlg = false">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="enterInfoDlg" scrollable max-width="600px" persistent>
      <widgets-enterprise-dialogs
        :id="activeId"
        :key="activeId"
        v-on:Close="closeEnterInfo"
      ></widgets-enterprise-dialogs>
    </v-dialog>
    <v-navigation-drawer
      v-model="filterDrawer"
      app
      right
    >
      <v-card class="mx-auto" flat>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="headline py-2">筛选</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-card-text>
          <v-sheet min-height="500">
            <div class="mt-2">
              <h2 class="subtitle-2 my-2">客户</h2>
              <v-autocomplete
                v-model="query.enterprise"
                label="请输入客户名称"
                autocomplete="off"
                :items="dealer_enterprise"
                item-text="name"
                item-value="_id"
                hide-no-data
                outlined
                dense
                @change="doAction('set_filter_branch')"
              ></v-autocomplete>
              <v-autocomplete
                autocomplete="off"
                v-model="query.branch"
                :items="arrBranch"
                @change="doAction('set_filter')"
                label="分支机构"
                hint="请选择企业的分支机构"
                outlined
                dense
                clearable
              ></v-autocomplete>
              <h2 class="subtitle-2 mt-2">工单状态</h2>
              <v-chip-group v-if="orderStatus.length" v-model="query.status" column multiple @change="doAction('set_filter')">
                <v-chip v-for="orders in orderStatus" :key="orders.code" :value="orders.code" filter label outlined>{{orders.name}}</v-chip>
              </v-chip-group>
              <h2 class="subtitle-2 mt-2">创建时间</h2>
              <v-menu ref="menuStartCreateTime" v-model="menuStartCreateTime"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.startCreateTime"
                    label="开始时间"
                    class="mt-2 mb-n4"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @change="doAction('set_filter')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="query.startCreateTime" no-title scrollable width="225" @input="doAction('set_filter'); menuStartCreateTime = false; clearTime(['createTime'])">
                </v-date-picker>
              </v-menu>
              <v-menu ref="menuStartCreateTime" v-model="menuEndCreateTime"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="query.endCreateTime"
                    label="结束时间"
                    class="mt-2 mb-n4"
                    readonly
                    outlined
                    dense
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @change="doAction('set_filter')"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="query.endCreateTime" no-title scrollable width="225" @input="doAction('set_filter'); menuEndCreateTime = false; clearTime(['createTime'])"></v-date-picker>
              </v-menu>
              <div v-if="this.query.types.includes('rent')">
                <h2 class="subtitle-2 my-2">租赁方式</h2>
                <v-chip-group column multiple @change="doAction('set_filter')" v-model="query.rent_type">
                  <v-chip filter label outlined value="0">融资租赁</v-chip>
                  <v-chip filter label outlined value="1">经营性租赁</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 my-2">租期</h2>
                <v-chip-group column multiple @change="doAction('set_filter')" v-model="query.lease_term">
                  <v-chip filter label outlined value="365">1年</v-chip>
                  <v-chip filter label outlined value="730">2年</v-chip>
                  <v-chip filter label outlined value="1095">3年</v-chip>
                  <v-chip filter label outlined value="1460">4年</v-chip>
                </v-chip-group>
                <h2 class="subtitle-2 my-2">起租日期</h2>
                <v-menu v-model="menuLeaseStartFrom"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="query.leaseStartFrom"
                      label="开始时间"
                      class="mt-2 mb-n4"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @change="doAction('set_filter')"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.leaseStartFrom" no-title scrollable width="225" @input="doAction('set_filter'); menuLeaseStartFrom = false">
                  </v-date-picker>
                </v-menu>
                <v-menu v-model="menuLeaseStartTo"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="query.leaseStartTo"
                      label="结束时间"
                      class="mt-2 mb-n4"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                      @change="doAction('set_filter')"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="query.leaseStartTo" no-title scrollable width="225" @input="doAction('set_filter'); menuLeaseStartTo = false"></v-date-picker>
                </v-menu>
                <h2 class="subtitle-2 my-2">支付周期</h2>
                <v-chip-group column multiple v-model="query.payment_cycle" @change="doAction('set_filter')">
                  <v-chip filter label outlined value="0">月付</v-chip>
                  <v-chip filter label outlined value="1">季付</v-chip>
                  <v-chip filter label outlined value="2">半年付</v-chip>
                  <v-chip filter label outlined value="3">年付</v-chip>
                </v-chip-group>
              </div>
            </div>
          </v-sheet>
        </v-card-text>
      </v-card>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="doAction('clear_filter')">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {
  ADD_MARK_ORDER_PURCHASE,
  FETCH_DEALER_ENTERPRISE,
  FETCH_ORDER_PURCHASE_LIST,
  REMOVE_MARK_ORDER_PURCHASE,
} from "@/store/actions.type";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";
import ApiService from "@/common/http";
export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
      },
      arrBranch: [],
      query: {
        key: "",
        enterprise: "",
        status: [],
        startCreateTime: '',
        endCreateTime: '',
        types: [
          'purchase', 'rent'
        ],
        rent_type: [],
        lease_term: [],
        leaseStartFrom: '',
        leaseStartTo: '',
        payment_cycle: [],
        branch: ''
      },
      defaultQuery: {
        key: "",
        enterprise: "",
        status: [],
        startCreateTime: '',
        endCreateTime: '',
        types: [
          'purchase', 'rent'
        ],
        rent_type: [],
        lease_term: [],
        leaseStartFrom: '',
        leaseStartTo: '',
        payment_cycle: [],
        branch: ''
      },
      optionsItem: {},
      active_tab: 0,
      // headers: [
      //   { text: '订单编号', value: 'order'},
      //   { text: 'DT号', value: 'dt_num'},
      //   { text: 'PO号', value: 'po_num'},
      //   { text: '客户', value: 'enterprise'},
      //   { text: '创建时间', value: 'createTime'},
      //   { text: '工单数', value: 'work_list'},
      //   { text: '型号', value: 'product_list'},
      //   { text: '剩余数量', value: 'quantity'},
      //   { text: '收货人', value: 'client'},
      //   { text: '收货人电话', value: 'client_tel'},
      //   { text: '收货人地址', value: 'client_address'},
      //   { text: '状态', value: 'status'},
      //   { text: '操作', value: 'actions'},
      // ],
      defaultHeader: [
        { text: '订单编号', value: 'order_number'},
        { text: 'PO号', value: 'po_number', width: '150px'},
        { text: 'DT号', value: 'out_number', width: '150px'},
        { text: '产品型号', value: 'product_part_list', width: '100px', sortable: false},
        { text: '产品名称', value: 'product_name_list', width: '100px', sortable: false},
        { text: '订单数量', value: 'product_quantity_list', width: '120px', align: " d-none", sortable: false},
        { text: '已出库数量', value: 'product_asset_list', width: '120px', align: " d-none", sortable: false},
        { text: '待出库数量', value: 'product_await_list', width: '120px', align: " d-none", sortable: false},
        { text: '已开单数量', value: 'product_cases_list', width: '120px', align: " d-none", sortable: false},
        { text: '已取消数量', value: 'product_cancel_list', width: '120px', align: " d-none", sortable: false},
        { text: '订单合计金额', value: 'total_price', width: '150px'},
        { text: '订单总数量', value: 'total_quantity', width: '120px'},
        { text: '产品种类', value: 'count_product', align: " d-none", width: '120px'},
        { text: '企业', value: 'enterprise', width: '150px'},
        { text: '分支机构', value: 'branch', align: " d-none", width: '150px'},
        { text: '销售', value: 'sales', width: '120px'},
        { text: '客户付款日', value: 'rent_info.payment_day', align: " d-none", width: '120px'},
        { text: '租赁方式', value: 'rent_info.rent_type', width: '120px'},
        { text: '起租日期', value: 'rent_info.lease_start', align: " d-none", width: '120px'},
        { text: '租期', value: 'rent_info.lease_term', align: " d-none", width: '120px'},
        { text: '支付周期', value: 'rent_info.payment_cycle', align: " d-none", width: '120px'},
        { text: '账期', value: 'rent_info.payment_period', align: " d-none", width: '80px'},
        { text: '期数', value: 'rent_info.total_period', align: " d-none", width: '120px'},
        { text: '到期时间', value: 'rent_info.expiry_date', align: " d-none", width: '120px'},
        { text: '资方', value: 'rent_info.stakeholders.investor', align: " d-none", width: '120px'},
        { text: '代理商', value: 'rent_info.stakeholders.agent', align: " d-none", width: '120px'},
        { text: '回收商', value: 'rent_info.stakeholders.recycler', align: " d-none", width: '120px'},
        { text: '签约方', value: 'rent_info.stakeholders.party', align: " d-none", width: '120px'},
        { text: '收货人姓名', value: 'delivery_info.name', align: " d-none", width: '115px'},
        { text: '收货人电话', value: 'delivery_info.phone', align: " d-none", width: '125px'},
        { text: '收货人地址', value: 'delivery_info.address', align: " d-none", width: '285px'},
        { text: '最后更新人', value: 'editor', align: " d-none", width: '120px'},
        { text: '订单创建人', value: 'creator', width: '120px'},
        { text: '创建时间', value: 'createTime', width: '100px'},
        { text: '更新时间', value: 'updateTime', align: " d-none", width: '100px'},
        { text: '状态', value: 'status', width: '100px'},
        { text: '操作', value: 'actions', width: '100px', sortable: false},
      ],
      orderList: [],
      orderCount: 0,
      filterDrawer: false,
      enterpriseList: [],
      isEnterpriseLoading: false,
      searchEnterprise: null,
      newOrderDlg: false,
      orderItem: {
        enterprise: '',
      },
      valid: true,
      productDlg: false,
      proHeaders: [
        { text: '产品名称', value: 'name' },
        { text: '数量', value: 'quantity' },
        { text: '单价', value: 'price' },
        { text: '小记', value: 'price_total' },
      ],
      prodcutList: [
        {
          name: 'iphone 12 pro max',
          quantity: 5,
          price: '10000',
          price_total: '50000'
        },
        {
          name: 'M1 Mbp',
          quantity: 5,
          price: '12999',
          price_total: '64995'
        }
      ],
      caseDlg: false,
      caseHeaders: [
        { text: '工单编号', value: 'code' },
        { text: '所属服务', value: 'service' },
        { text: '当前任务', value: 'task' },
        { text: '关联设备数量', value: 'quantity' },
        { text: '状态', value: 'status' },
        { text: '创建时间', value: 'createTime' },
      ],
      caseList: [
        {
          code: '202105191257154939',
          service: '设备发放',
          task: '发货',
          quantity: '5',
          status: '处理中',
          createTime: '2021-06-10 23:59:59'
        }
      ],
      menuStartCreateTime: false,
      menuEndCreateTime: false,
      activeId: '',
      enterInfoDlg: false,
      headerItem: [],
      loadingDownload: false,
      menuLeaseStartFrom: false,
      menuLeaseStartTo: false,
    }
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_DEALER_ENTERPRISE),
    ]).then(() => {
      next();
    });
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "dealer_enterprise",
      "orderPurchaseLoading",
      "orderPurchaseList",
      "orderPurchaseCount",
    ]),
    orderStatus () {
      return Util.categories('orderStatus')
    },
    leaseTerm() {
      return Util.categories("leaseTerm");
    },
    computedHeaders() {
      let arrHeaderItem = this.headerItem
      return arrHeaderItem.filter(item => !item.align)
    }
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList(this.query);
      },
      deep: true,
    },
  },
  created() {
    this.getHeader('orderHeader', 2)
  },
  methods: {
    getList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query)
        if (this.query.status && !Array.isArray(this.query.status)) this.query.status = this.query.status.split(',')
        if (this.query.types && !Array.isArray(this.query.types)) this.query.types = this.query.types.split(',')
        if (this.query.rent_type && !Array.isArray(this.query.rent_type)) this.query.rent_type = this.query.rent_type.split(',')
        if (this.query.lease_term && !Array.isArray(this.query.lease_term)) this.query.lease_term = this.query.lease_term.split(',')
        if (this.query.payment_cycle && !Array.isArray(this.query.payment_cycle)) this.query.payment_cycle = this.query.payment_cycle.split(',')
      }

      let urlQuery = "";
      if (query.key) urlQuery += "&key=" + query.key
      if (query.enterprise) urlQuery += "&enterprise=" + query.enterprise
      if (query.branch) urlQuery += '&branch='+ query.branch
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.startCreateTime) urlQuery += '&startTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endTime='+ query.endCreateTime
      if (this.query.types) urlQuery += '&types='+ this.query.types
      if (this.query.follow) urlQuery += '&follow='+ this.query.follow

      if (this.query.types.includes('rent')) {
        if (this.query.lease_term) urlQuery += '&lease_term='+ query.lease_term
        if (this.query.rent_type) urlQuery += '&rent_type='+ query.rent_type
        if (this.query.payment_cycle) urlQuery += '&payment_cycle='+ query.payment_cycle
        if (this.query.leaseStartFrom) urlQuery += '&lease_start_begin='+ query.leaseStartFrom
        if (this.query.leaseStartTo) urlQuery += '&lease_start_finish='+ query.leaseStartTo
      }


      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_ORDER_PURCHASE_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    doAction(action) {
      switch (action) {
        case "search_item": {
          this.getList(this.query);
          break;
        }
        case "set_filter": {
          this.$router.push({ query: {} });
          this.getList(this.query);
          break;
        }
        case "clear_filter": {
          this.query = Object.assign({}, this.defaultQuery);
          this.$router.push({ query: {} });
          this.getList();
          break;
        }
        case "select_enterprise": {
          this.newOrderDlg = true
          break;
        }
        case 'cancel_header': {
          this.getHeader('orderHeader', 2)
          this.$forceUpdate()
          break
        }
        case 'default_header': {

          break
        }
        case 'set_header': {
          this.getHeader('orderHeader', 2)
          this.$forceUpdate()
          break
        }
        case 'reload_item': {
          this.optionsItem.page = 1
          this.getList()
          break
        }
        case 'export_item': {
          this.loadingDownload = true
          this.exportList(this.query)
          break
        }
        case 'set_filter_branch': {
          if (this.query.enterprise) {
            let objEnterprise = this.dealer_enterprise.find(v => v._id === this.query.enterprise)
            if (objEnterprise && objEnterprise.branch) this.arrBranch = [...objEnterprise.branch]
          } else {
            this.arrBranch = []
          }
          this.doAction('set_filter')
          break
        }
      }
    },
    getHeader(model, version) {
      let userHeader = this.currentUser[model];
      if (userHeader.content.length) {
        if (userHeader.version < version) {
          this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
        } else {
          this.headerItem = JSON.parse(JSON.stringify(userHeader.content))
        }
      } else {
        this.headerItem = JSON.parse(JSON.stringify(this.defaultHeader))
      }
    },
    openNewOrder(type) {
      this.$router.push('/dealer/workbench/order/edit?orderType='+type)
    },
    clearTime(arrModel = []) {
      for (const model of arrModel) {
        this.query[model] = ''
      }
    },
    updateList() {
      this.getList(this.query)
    },
    openEnterInfo(id) {
      this.activeId = id;
      this.enterInfoDlg = true;
    },
    closeEnterInfo() {
      this.enterInfoDlg = false;
    },
    copyOrder(id, type) {
      this.$router.push(
        "/dealer/workbench/order/edit?orderId="+id+'&type=copy&orderType='+(type===0?'purchase':'rent')
      );
    },
    leaseOrder(id) {
      this.$router.push(
        "/dealer/workbench/order/edit?orderId="+id+'&type=lease&orderType=rent'
      );
    },
    exportList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page } = this.optionsItem;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      let urlQuery = '';
      if (query.key) urlQuery += '&key='+ query.key
      if (query.enterprise) urlQuery += '&enterprise='+ query.enterprise
      if (query.branch) urlQuery += '&branch='+ query.branch
      if (this.query.status.length) urlQuery += '&status='+ query.status
      if (this.query.startCreateTime) urlQuery += '&startTime='+ query.startCreateTime
      if (this.query.endCreateTime) urlQuery += '&endTime='+ query.endCreateTime
      //租赁
      if (this.query.types) urlQuery += '&types='+ this.query.types
      if (this.query.follow) urlQuery += '&follow='+ this.query.follow
      if (this.query.lease_term) urlQuery += '&lease_term='+ query.lease_term
      if (this.query.rent_type) urlQuery += '&rent_type='+ query.rent_type
      if (this.query.payment_cycle) urlQuery += '&payment_cycle='+ query.payment_cycle
      if (this.query.leaseStartFrom) urlQuery += '&lease_start_begin='+ query.leaseStartFrom
      if (this.query.leaseStartTo) urlQuery += '&lease_start_finish='+ query.leaseStartTo

      if (Object.keys(this.optionsItem).length > 0) {
        ApiService.download('/order/purchase/export?page=' + page + '&limit=1000&sort=' + sort + urlQuery, {
          responseType:'blob'
        })
          .then(resp => {
            let fileName = '采购订单导出列表.xlsx';
            let contentDisposition = resp.headers['content-disposition'];
            if (contentDisposition) {
              fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
            }
            let blob = new Blob([resp.data], {type: 'application/octet-stream'});
            if (window.navigator.msSaveOrOpenBlob) { //支持IE
              navigator.msSaveBlob(blob, fileName);
            } else {
              let link = document.createElement('a');
              link.style.display = "none";
              link.href = window.URL.createObjectURL(blob);
              link.download = fileName;
              document.body.appendChild(link);
              link.click();
              //释放内存
              window.URL.revokeObjectURL(link.href);
              document.body.removeChild(link);
            }
            this.loadingDownload = false
          })
          .catch(err => { // 请求失败处理
            store.commit(SET_ERROR, {msg: err});
          });
      }

    },
    markOrder(orderId, result) {
      let actCode = ADD_MARK_ORDER_PURCHASE
      if (!result) actCode = REMOVE_MARK_ORDER_PURCHASE
      store.dispatch(actCode, orderId)
        .then(() => {
          store.commit(SET_ERROR, {msg: '操作成功', color: 'primary'});
          this.getList()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    todoList(query = 'all') {
      this.query = Object.assign({}, this.defaultQuery)
      if (query === 'purchase') this.query.types = ['purchase']
      if (query === 'rental') this.query.types = ['rent']
      if (query === 'follow') this.query.follow = this.currentUser._id
      this.getList();
    },
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.v-expansion-panel-content .v-expansion-panel-content__wrap {
  padding: 0 0 0 0 !important;
}
.addIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
</style>
