<template>
  <v-container fluid class="pb-16">
    <div class="subtitle-1">{{ riskApproval.title }}</div>
    <v-list two-line>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>流水号</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.serialNumber }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>申请人</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.creator?.personal?.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>申请时间</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.createTime | formatTime }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>客户名称</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.enterprise?.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>签约主体</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.kingdeeOrg?.FNAME }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>业务类型</v-list-item-subtitle>
          <v-list-item-title>{{ businessTypeList.find(n => n.code === riskApproval.businessType)?.name}}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>待授信公司简称</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.cusShortName }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>授信资质分类</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.riskEnterpriseType?.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>联系人</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.cusContact }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>成立时间</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.startDate | formatTime }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>注册资金（RMB）</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.registCapiAmt | formatMoney }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>职员人数</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.peopleNumber }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>付款条件</v-list-item-subtitle>
          <v-list-item-title>{{ payConditionList.find(n => n.code.toString() === riskApproval.payCondition)?.name
            }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>新/旧客户</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.isNewCus ? '新' : '旧' }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple v-if="riskApproval.isNewCus">
        <v-list-item-content>
          <v-list-item-subtitle>原账期天数</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.oldDays }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple v-if="riskApproval.isNewCus">
        <v-list-item-content>
          <v-list-item-subtitle>原额度</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.oldQuota | formatMoney }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>本次申请账期</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.days }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>本次申请额度</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.quota | formatMoney }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>开票天数</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.invoiceDays }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>客户基本情况</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.cusDesc }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>业务机会描述</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.serviceDesc }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>主要竞争对手</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.enemy }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>最终用户</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.finishCus }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>关联公司</v-list-item-subtitle>
          <v-list-item-title>{{ riskApproval.relaCus }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-subheader>审批意见</v-subheader>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>意见</v-list-item-subtitle>
          <v-list-item-title>{{ "" }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>尽调结果</v-list-item-subtitle>
          <v-list-item-title>{{ "" }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-row>
        <v-col cols="6" offset="3">
          <v-btn block @click="calCreditLine">
            模型计算
          </v-btn>
        </v-col>
      </v-row>
      <v-list-item ripple>
        <v-list-item-content>
          <v-list-item-subtitle>模型计算结果</v-list-item-subtitle>
          <v-list-item-title>得分：{{ riskEnterprise.totalScore }}</v-list-item-title>
          <v-list-item-title>峰值额度：{{ riskEnterprise.creditLine }}元</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-footer app absolute class="d-flex justify-center align-center pa-4 pb-8">
      <v-row no-gutters>
        <v-col cols="6" class="pl-2">
          <v-btn block @click="doAction('reject')">
            拒绝
          </v-btn>
        </v-col>
        <v-col cols="6" class="pr-2">
          <v-btn block color="green" @click="doAction('approve')">
            同意
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog v-model="dlgApproval" scrollable persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ dlgTitle }}
          <v-spacer></v-spacer>
          <v-btn icon @click="close"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-textarea v-model="comment" auto-grow rows="4" label="审批意见" hint="请输入审批意" value="" outlined></v-textarea>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">关闭</v-btn>
          <v-btn text color="secondary" :loading="isLoading" @click="submit">
            {{ dlgTitle }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "@/store";
import {
  FETCH_RISK_APPROVAL,
  FETCH_RISK_MODEL_LIST,
  APPROVE_RISK_APPROVAL,
  REJECT_RISK_APPROVAL,
  CAL_CREDITLINE,
} from "@/store/actions.type";
import { SET_ERROR } from '@/store/mutations.type';
import Util from "@/common/util";
export default {
  data: () => ({
    riskApproval: {},
    dlgTitle: '',
    isLoading: false,
    dlgApproval: false,
    comment: '',
    riskEnterprise: {},
  }),
  computed: {
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
  },
  async created() {
    if (this.$route.params.id !== '0') {
      await this.getRiskApproval(this.$route.params.id)
    }
  },
  methods: {
    async getRiskApproval(id) {
      const data = await this.$store.dispatch(FETCH_RISK_APPROVAL, id);
      this.riskApproval = data;
    },
    async submit() {
      try {
        this.isLoading = true;
        if (this.dlgTitle === '同意') {
          await this.$store.dispatch(APPROVE_RISK_APPROVAL, {
            id: this.$route.params.id,
            comment: this.comment,
            riskEnterpriseId: this.riskEnterprise._id || null,
          });

          this.isLoading = false;
          this.dlgApproval = false;
          this.$router.push({ path: '/dealer/creditline/review' });
          return;
        }

        if (this.dlgTitle === '拒绝') {
          await this.$store.dispatch(REJECT_RISK_APPROVAL, {
            id: this.$route.params.id,
            comment: this.comment,
          });

          this.isLoading = false;
          this.dlgApproval = false;
          this.$router.push({ path: '/dealer/creditline/review' });
        }
      } catch (err) {
        this.isLoading = false;
        store.commit(SET_ERROR, { msg: err.response.data.message });
      }
    },
    async calCreditLine() {
      try {
        // 根据业务类型获取最新的模型
        const riskModelListResult = await store.dispatch(FETCH_RISK_MODEL_LIST, 'businessType=' + this.riskApproval.businessType + '&page=1&limit=1&sort=-updateTime');

        if (riskModelListResult.docs.length == 0) {
          store.commit(SET_ERROR, { msg: '没有找到模型' });
          return;
        }

        // 风控计算
        const calCreditLineResult = await store.dispatch(CAL_CREDITLINE, {
          enterpriseId: this.riskApproval.enterprise.id,
          modelId: riskModelListResult.docs[0]._id,
          enterpriseTypeId: this.riskApproval.riskEnterpriseType.id,
        });

        this.riskEnterprise = calCreditLineResult.data;
      } catch (error) {
        store.commit(SET_ERROR, { msg: error.response.data.message });
      }
    },
    doAction(action) {
      switch (action) {
        case 'approve': {
          this.dlgTitle = '同意';
          this.dlgApproval = true;
          break
        }
        case 'reject': {
          this.dlgTitle = '拒绝';
          this.dlgApproval = true;
          break
        }
      }
    },
    showDlg(ys) {
      if (!ys.distinctFYSDH) {
        return;
      }

      this.overdueItem = {
        distinctFYSDH: ys.distinctFYSDH.join('，'),
      };
      this.dlgApproval = true;
    },
    close() {
      this.dlgApproval = false;
      this.clicked = false;
    },
    copyText() {
      navigator.clipboard.writeText(this.overdueItem.distinctFYSDH)
        .then(() => {
          this.clicked = true
        })
        .catch(() => {
          this.clicked = false
        })
    },
  },
}
</script>
<style scoped>
.v-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}
</style>
