var render = function () {
  var _vm$riskApproval$crea, _vm$riskApproval$crea2, _vm$riskApproval$ente, _vm$riskApproval$king, _vm$businessTypeList$, _vm$riskApproval$risk, _vm$payConditionList$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pb-16",
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "subtitle-1"
  }, [_vm._v(_vm._s(_vm.riskApproval.title))]), _c('v-list', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("流水号")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.serialNumber))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("申请人")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$riskApproval$crea = _vm.riskApproval.creator) === null || _vm$riskApproval$crea === void 0 ? void 0 : (_vm$riskApproval$crea2 = _vm$riskApproval$crea.personal) === null || _vm$riskApproval$crea2 === void 0 ? void 0 : _vm$riskApproval$crea2.name))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("申请时间")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.riskApproval.createTime)))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("客户名称")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$riskApproval$ente = _vm.riskApproval.enterprise) === null || _vm$riskApproval$ente === void 0 ? void 0 : _vm$riskApproval$ente.name))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("签约主体")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$riskApproval$king = _vm.riskApproval.kingdeeOrg) === null || _vm$riskApproval$king === void 0 ? void 0 : _vm$riskApproval$king.FNAME))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("业务类型")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$businessTypeList$ = _vm.businessTypeList.find(function (n) {
    return n.code === _vm.riskApproval.businessType;
  })) === null || _vm$businessTypeList$ === void 0 ? void 0 : _vm$businessTypeList$.name))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("待授信公司简称")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.cusShortName))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("授信资质分类")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$riskApproval$risk = _vm.riskApproval.riskEnterpriseType) === null || _vm$riskApproval$risk === void 0 ? void 0 : _vm$riskApproval$risk.name))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("联系人")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.cusContact))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("成立时间")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm._f("formatTime")(_vm.riskApproval.startDate)))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("注册资金（RMB）")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.riskApproval.registCapiAmt)))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("职员人数")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.peopleNumber))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("付款条件")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$payConditionList$ = _vm.payConditionList.find(function (n) {
    return n.code.toString() === _vm.riskApproval.payCondition;
  })) === null || _vm$payConditionList$ === void 0 ? void 0 : _vm$payConditionList$.name))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("新/旧客户")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.isNewCus ? '新' : '旧'))])], 1)], 1), _vm.riskApproval.isNewCus ? _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("原账期天数")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.oldDays))])], 1)], 1) : _vm._e(), _vm.riskApproval.isNewCus ? _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("原额度")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.riskApproval.oldQuota)))])], 1)], 1) : _vm._e(), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("本次申请账期")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.days))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("本次申请额度")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.riskApproval.quota)))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("开票天数")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.invoiceDays))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("客户基本情况")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.cusDesc))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("业务机会描述")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.serviceDesc))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("主要竞争对手")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.enemy))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("最终用户")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.finishCus))])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("关联公司")]), _c('v-list-item-title', [_vm._v(_vm._s(_vm.riskApproval.relaCus))])], 1)], 1), _c('v-divider'), _c('v-subheader', [_vm._v("审批意见")]), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("意见")]), _c('v-list-item-title', [_vm._v(_vm._s(""))])], 1)], 1), _c('v-divider'), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("尽调结果")]), _c('v-list-item-title', [_vm._v(_vm._s(""))])], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "offset": "3"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.calCreditLine
    }
  }, [_vm._v(" 模型计算 ")])], 1)], 1), _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("模型计算结果")]), _c('v-list-item-title', [_vm._v("得分：" + _vm._s(_vm.riskEnterprise.totalScore))]), _c('v-list-item-title', [_vm._v("峰值额度：" + _vm._s(_vm.riskEnterprise.creditLine) + "元")])], 1)], 1)], 1), _c('v-footer', {
    staticClass: "d-flex justify-center align-center pa-4 pb-8",
    attrs: {
      "app": "",
      "absolute": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    staticClass: "pl-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('reject');
      }
    }
  }, [_vm._v(" 拒绝 ")])], 1), _c('v-col', {
    staticClass: "pr-2",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    attrs: {
      "block": "",
      "color": "green"
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('approve');
      }
    }
  }, [_vm._v(" 同意 ")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "max-width": "600px"
    },
    model: {
      value: _vm.dlgApproval,
      callback: function callback($$v) {
        _vm.dlgApproval = $$v;
      },
      expression: "dlgApproval"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dlgTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-form', [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "rows": "4",
      "label": "审批意见",
      "hint": "请输入审批意",
      "value": "",
      "outlined": ""
    },
    model: {
      value: _vm.comment,
      callback: function callback($$v) {
        _vm.comment = $$v;
      },
      expression: "comment"
    }
  })], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("关闭")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "loading": _vm.isLoading
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" " + _vm._s(_vm.dlgTitle) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }