var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', {
    staticClass: "fill-height overflow-y-auto mb-16"
  }, [_vm._l(_vm.riskApprovalList, function (item, index) {
    return _c('v-col', {
      key: index,
      attrs: {
        "cols": "12",
        "md": "6"
      }
    }, [_c('v-card', {
      staticClass: "block-item elevation-2",
      on: {
        "click": function click($event) {
          return _vm.goTo(item);
        }
      }
    }, [_c('v-card-text', [_c('div', {
      staticClass: "block-content"
    }, [_c('div', {
      staticClass: "text-content"
    }, [_c('v-list', {
      attrs: {
        "dense": ""
      }
    }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("流水号：" + _vm._s(item.serialNumber))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-row', {
      attrs: {
        "no-gutters": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v("客户名称：")]), _c('v-col', {
      staticClass: "text-wrap"
    }, [_vm._v(_vm._s(item.enterprise.name))])], 1)], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("付款条件：" + _vm._s(_vm.payConditionList.find(function (n) {
      return n.code.toString() === item.payCondition;
    }).name))])], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-row', [_c('v-col', [_vm._v("申请账期：" + _vm._s(item.days) + "天")]), _c('v-col', [_vm._v("申请额度：" + _vm._s(_vm._f("formatMoney")(item.quota)))])], 1)], 1)], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_c('v-row', [_c('v-col', [_vm._v(_vm._s(item.creator.personal.name))]), _c('v-col', [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
      staticClass: "action-button"
    }, [_c('v-icon', [_vm._v("keyboard_arrow_right")])], 1)])])], 1)], 1);
  }), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12"
    }
  }, [this.query.page * this.query.limit < _vm.riskApprovalCount ? _c('v-progress-circular', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.infiniteScrolling,
        options: {
          threshold: [1.0]
        }
      },
      expression: "{\n        handler: infiniteScrolling,\n        options: {\n            threshold: [1.0]\n        }\n      }"
    }],
    attrs: {
      "indeterminate": "",
      "color": "secondary",
      "width": "2"
    }
  }) : _c('span', {
    staticClass: "font-weight-light"
  }, [_vm._v("已展示" + _vm._s(_vm.riskApprovalCount) + "个授信审批，没有更多数据了。")])], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }