<template>
  <div class="fluid">
    <v-data-table :headers="headerTable" :items="riskModelList" :options.sync="optionsTable"
      :server-items-length="riskModelCount" :loading="riskModelLoading" loading-text="数据读取中... 请稍后"
      :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}" fixed-header height="600" item-key="_id"
      transition>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>模型列表</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="query.key" label="关键字搜索（按回车）" prepend-inner-icon="search"
            :background-color="$vuetify.theme.dark ? '' : 'grey lighten-4'" solo flat dense hide-details clearable
            @keydown.enter="searchItem" @click:clear="clearSearchItem"></v-text-field>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ma-2" depressed color="secondary" @click="doAction('create_item')">
                <v-icon>add</v-icon> 创建模型
              </v-btn>
            </template>
          </v-menu>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                <v-icon small>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.businessType="{ item }">
        <td nowrap="true">{{businessTypeList.find(o => o.code === item.businessType).name}}</td>
      </template>
      <template v-slot:item.enterpriseCount="{ item }">
        <td nowrap="true">
          <router-link :to="'/dealer/system/risk/enterprise'">{{ item.enterpriseCount }}</router-link>
        </td>
      </template>
      <template v-slot:item.updateTime="{ item }">
        <td nowrap="true">{{item.updateTime | formatTime}}</td>
      </template>
      <template v-slot:item.actions="{ item }">
        <td nowrap="true">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="editItem(item)">edit</v-icon>
            </template>
            <span>编辑</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="copyItem(item)">content_copy</v-icon>
            </template>
            <span>复制模型</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small class="mr-2" @click="deleteItem(item)">delete</v-icon>
            </template>
            <span>删除</span>
          </v-tooltip>
        </td>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" depressed @click="getDataList()">刷新</v-btn>
      </template>
    </v-data-table>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">业务类型</h2>
        <v-autocomplete class="mt-2" v-model="query.businessType" :items="businessTypeList" @change="setFilter" item-text="name"
          item-value="code" autocomplete="off" outlined dense label="业务类型" hint="请选择业务类型" chips deletable-chips multiple
          small-chips>
        </v-autocomplete>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>
<script>
import store from "@/store";
import {
  FETCH_RISK_MODEL_LIST,
  DELETE_RISK_MODEL,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
        negative: val => ( val >= 0) || '不能小于0'
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedItem: {
        _id: '',
        year: null,
        businessType: '',
      },
      defaultItem: {
        _id: '',
        year: null,
        businessType: '',
      },
      filterDrawer: false,
      query: {
        key: '',
        year: null,
        businessType: [],
      },
      defaultQuery: {
        key: '',
        year: null,
        businessType: [],
      },
      headerTable: [
        {text: '模型名称', width: '125px', value: 'name'},
        {text: '业务类型', width: '125px', value: 'businessType'},
        {text: '应用企业', width: '125px', value: 'enterpriseCount'},
        {text: '备注', width: '125px', value: 'remarks'},
        {text: '更新时间', width: '125px', value: 'updateTime'},
        {text: '操作', width: '85px', value: 'actions', sortable: false}
      ],
      optionsTable: {},
      dlgInfo: false,
      activeType: 'import',
      importExcelNotice: [['请使用此模版批量导入风控经济数据，*为必填项目。']],
      importSuccess: 0,
      importFailed: [],
      importEnd: false,
      exportEnd: true,
    }
  },
  async created() {
    await this.getDataList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "currentEmployer",
      "riskModelLoading",
      "riskModelList",
      "riskModelCount"]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  methods: {
    doAction(action) {
      switch (action) {
        case 'create_item': {
          this.$router.push({path:'/dealer/system/risk/model/edit/0'})
          break
        }
      }
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList({ key: encodeURIComponent(this.query.key), selected: this.query.filterItem })
    },
    async getDataList() {
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.year) urlQuery += '&year=' + this.query.year
      if (this.query.businessType.length) urlQuery += '&businessType='+ encodeURIComponent(this.query.businessType)
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_RISK_MODEL_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    async deleteItem(item) {
      if (confirm(`确定要 删除 ${item.name}？`)) {
        this.$store.dispatch(DELETE_RISK_MODEL, item)
          .then(() => {
            this.getDataList()
          })
          .catch((err) => {
            this.$store.commit(SET_ERROR, { msg: err.response.data.message });
          });
      }
    },
    closeItem() {
      this.dlgInfo = false
    },
    editItem(item) {
      this.$router.push({path:'/dealer/system/risk/model/edit/' + item._id})
    },
    copyItem(item) {
      this.$router.push({ path: '/dealer/system/risk/model/edit/' + item._id + '?copy=1' })
    },
    close () {
      this.dlgTitle = '';
      this.dlgEdit = false;
      this.editedItem = Object.assign({}, this.defaultItem);
    },
    importItem() {
      this.activeType = 'import'
      this.dlgInfo = true
    },
    exportItem(){
      if (this.exportEnd) {
        this.exportFile(this.query)
      }
    },
  }
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

