var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('v-form', {
    staticClass: "mb-16",
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('div', [_vm._v(" 申请人：" + _vm._s(_vm.currentUser.personal.name) + " ")]), _c('div', [_vm._v(" 申请时间：" + _vm._s(_vm.date) + " ")])]), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.kingdeeOrgList,
      "rules": [_vm.rules.selected],
      "item-text": "FNAME",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "签约主体*",
      "hint": "请选择签约主体"
    },
    on: {
      "change": _vm.getKingdeeCustomer
    },
    model: {
      value: _vm.editedItem.kingdeeOrg,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "kingdeeOrg", $$v);
      },
      expression: "editedItem.kingdeeOrg"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.businessTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "label": "业务类型*",
      "hint": "请选择业务类型"
    },
    model: {
      value: _vm.editedItem.businessType,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "businessType", $$v);
      },
      expression: "editedItem.businessType"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "待授信公司简称*"
    },
    model: {
      value: _vm.editedItem.cusShortName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusShortName", $$v);
      },
      expression: "editedItem.cusShortName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cusNameList,
      "loading": _vm.isCusNameListLoading,
      "search-input": _vm.searchCusName,
      "rules": [_vm.rules.required],
      "item-text": "Name",
      "item-value": "Name",
      "autocomplete": "off",
      "label": "待授信公司工商全称*"
    },
    on: {
      "change": _vm.getKingdeeCustomer,
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchCusName = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchCusName = $event;
      }
    },
    model: {
      value: _vm.editedItem.cusName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusName", $$v);
      },
      expression: "editedItem.cusName"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.riskEnterpriseTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "label": "授信资质分类*",
      "hint": "请选择授信资质分类"
    },
    model: {
      value: _vm.editedItem.riskEnterpriseTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "riskEnterpriseTypeId", $$v);
      },
      expression: "editedItem.riskEnterpriseTypeId"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "联系人*"
    },
    model: {
      value: _vm.editedItem.cusContact,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusContact", $$v);
      },
      expression: "editedItem.cusContact"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "nudge-right": 40,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "成立日期*",
            "readonly": "",
            "rules": [_vm.rules.required]
          },
          model: {
            value: _vm.editedItem.startDate,
            callback: function callback($$v) {
              _vm.$set(_vm.editedItem, "startDate", $$v);
            },
            expression: "editedItem.startDate"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.startMenu,
      callback: function callback($$v) {
        _vm.startMenu = $$v;
      },
      expression: "startMenu"
    }
  }, [_c('v-date-picker', {
    on: {
      "input": function input($event) {
        _vm.startMenu = false;
      }
    },
    model: {
      value: _vm.editedItem.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "startDate", $$v);
      },
      expression: "editedItem.startDate"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "注册资金（RMB）*"
    },
    model: {
      value: _vm.editedItem.registCapiAmt,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "registCapiAmt", $$v);
      },
      expression: "editedItem.registCapiAmt"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "职员人数*"
    },
    model: {
      value: _vm.editedItem.peopleNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "peopleNumber", $$v);
      },
      expression: "editedItem.peopleNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.payConditionList,
      "rules": [_vm.rules.required],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "label": "付款条件*",
      "hint": "请选择付款条件"
    },
    model: {
      value: _vm.editedItem.payCondition,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "payCondition", $$v);
      },
      expression: "editedItem.payCondition"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.cusTypeList,
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "label": "新/旧客户*",
      "hint": "请选择新/旧客户"
    },
    on: {
      "change": _vm.getKingdeeCustomer
    },
    model: {
      value: _vm.editedItem.isNewCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "isNewCus", $$v);
      },
      expression: "editedItem.isNewCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.isNewCus ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.isNewCus,
      "type": "number",
      "label": "原账期天数*"
    },
    model: {
      value: _vm.editedItem.oldDays,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "oldDays", $$v);
      },
      expression: "editedItem.oldDays"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.isNewCus ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.isNewCus,
      "type": "number",
      "label": "原额度*"
    },
    model: {
      value: _vm.editedItem.oldQuota,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "oldQuota", $$v);
      },
      expression: "editedItem.oldQuota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.payCondition == 1,
      "type": "number",
      "label": "本次申请账期*"
    },
    model: {
      value: _vm.editedItem.days,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "days", $$v);
      },
      expression: "editedItem.days"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": _vm.editedItem.payCondition == 1 ? [] : [_vm.rules.required],
      "disabled": _vm.editedItem.payCondition == 1,
      "type": "number",
      "label": "本次申请额度*"
    },
    model: {
      value: _vm.editedItem.quota,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "quota", $$v);
      },
      expression: "editedItem.quota"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "number",
      "label": "开票天数*"
    },
    model: {
      value: _vm.editedItem.invoiceDays,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "invoiceDays", $$v);
      },
      expression: "editedItem.invoiceDays"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "auto-grow": "",
      "rows": "3",
      "label": "客户基本情况*",
      "hint": "客户概括/背景",
      "value": ""
    },
    model: {
      value: _vm.editedItem.cusDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cusDesc", $$v);
      },
      expression: "editedItem.cusDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "auto-grow": "",
      "rows": "3",
      "label": "业务机会描述*",
      "hint": "商机概括/规模/盈利水平",
      "value": ""
    },
    model: {
      value: _vm.editedItem.serviceDesc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "serviceDesc", $$v);
      },
      expression: "editedItem.serviceDesc"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "auto-grow": "",
      "rows": "3",
      "label": "主要竞争对手*",
      "hint": "列出至少三个",
      "value": ""
    },
    model: {
      value: _vm.editedItem.enemy,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "enemy", $$v);
      },
      expression: "editedItem.enemy"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "最终用户*",
      "hint": "若无穿透的底层客户，即：申请授信客户即最终客户，则填写“同授信客户”"
    },
    model: {
      value: _vm.editedItem.finishCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "finishCus", $$v);
      },
      expression: "editedItem.finishCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "auto-grow": "",
      "rows": "3",
      "label": "关联公司",
      "hint": "请列举所有会使用的业务主体",
      "value": ""
    },
    model: {
      value: _vm.editedItem.relaCus,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "relaCus", $$v);
      },
      expression: "editedItem.relaCus"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "items": _vm.accountList,
      "rules": [_vm.rules.selected],
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "chips": "",
      "small-chips": "",
      "deletable-chips": "",
      "label": "授信完成后抄送人员*",
      "hint": "请查找并选择抄送人员",
      "multiple": ""
    },
    model: {
      value: _vm.editedItem.cc,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "cc", $$v);
      },
      expression: "editedItem.cc"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mb-4",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.preApply
    }
  }, [_vm._v("分值测算")])], 1), _vm.showResult ? _c('v-list-item', {
    attrs: {
      "ripple": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("预授信结果")]), _c('v-list-item-title', [_vm._v("得分：" + _vm._s(_vm.preApplyResult.totalScore))]), _vm.preApplyResult.canSubmit ? _c('v-list-item-title', {
    staticStyle: {
      "color": "green"
    }
  }, [_vm._v(" 本次授信额度通过 ")]) : _c('v-list-item-title', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" 本次授信额度未通过 ")])], 1)], 1) : _vm._e(), _vm.preApplyResult.canSubmit ? _c('v-row', {
    staticClass: "my-4",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交授信申请")])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }