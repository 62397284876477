<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>企业授信申请详情</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-subheader>授信申请（{{ approvalStatusList.find(n=>n.code == riskApproval.instanceStatus)?.name }}）</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            流水号
          </v-col>
          <v-col cols="2">
            {{ riskApproval.serialNumber }}
          </v-col>
          <v-col cols="2">
            申请人
          </v-col>
          <v-col cols="2">
            {{ riskApproval.creator?.personal?.name }}
          </v-col>
          <v-col cols="2">
            申请时间
          </v-col>
          <v-col cols="2">
            {{ riskApproval.createTime | formatTime }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            客户名称
          </v-col>
          <v-col cols="2">
            {{ riskApproval.enterprise?.name }}
          </v-col>
          <v-col cols="2">
            签约主体
          </v-col>
          <v-col cols="2">
            {{ riskApproval.kingdeeOrg?.FNAME }}
          </v-col>
          <v-col cols="2">
            业务类型
          </v-col>
          <v-col cols="2">
            {{ businessTypeList.find(n => n.code === riskApproval.businessType)?.name }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            待授信公司简称
          </v-col>
          <v-col cols="2">
            {{ riskApproval.cusShortName }}
          </v-col>
          <v-col cols="2">
            授信资质分类
          </v-col>
          <v-col cols="2">
            {{ riskApproval.riskEnterpriseType?.name }}
          </v-col>
          <v-col cols="2">
            联系人
          </v-col>
          <v-col cols="2">
            {{ riskApproval.cusContact }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            成立时间
          </v-col>
          <v-col cols="2">
            {{ riskApproval.startDate | formatTime }}
          </v-col>
          <v-col cols="2">
            注册资金（RMB）
          </v-col>
          <v-col cols="2">
            {{ riskApproval.registCapiAmt | formatMoney }}
          </v-col>
          <v-col cols="2">
            职员人数
          </v-col>
          <v-col cols="2">
            {{ riskApproval.peopleNumber }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            付款条件
          </v-col>
          <v-col cols="2">
            {{ payConditionList.find(n => n.code.toString() === riskApproval.payCondition)?.name }}
          </v-col>
          <v-col cols="2">
            新/旧客户
          </v-col>
          <v-col cols="2">
            {{ riskApproval.isNewCus ? '新' : '旧' }}
          </v-col>
          <v-col cols="2">
            原账期天数
          </v-col>
          <v-col cols="2">
            {{ riskApproval.oldDays ? riskApproval.oldDays : '-' }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            原额度
          </v-col>
          <v-col cols="2">
            {{ riskApproval.oldQuota ? (riskApproval.oldQuota | formatMoney) : '-' }}
          </v-col>
          <v-col cols="2">
            本次申请账期
          </v-col>
          <v-col cols="2">
            {{ riskApproval.days }}
          </v-col>
          <v-col cols="2">
            本次申请额度
          </v-col>
          <v-col cols="2">
            {{ riskApproval.quota | formatMoney }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            开票天数
          </v-col>
          <v-col cols="2">
            {{ riskApproval.invoiceDays }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            客户基本情况
          </v-col>
          <v-col cols="10">
            {{ riskApproval.cusDesc }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            业务机会描述
          </v-col>
          <v-col cols="10">
            {{ riskApproval.serviceDesc }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            主要竞争对手
          </v-col>
          <v-col cols="10">
            {{ riskApproval.enemy }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            最终用户
          </v-col>
          <v-col cols="10">
            {{ riskApproval.finishCus }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            关联公司
          </v-col>
          <v-col cols="10">
            {{ riskApproval.relaCus }}
          </v-col>
        </v-row>
        <!-- <v-subheader>审批意见</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-data-table :headers="headerTable" :items="riskApproval.Data" :loading="qccAcctScanLoading"
          loading-text="数据读取中... 请稍后" fixed-header height="600" transition>
          <template v-slot:item.DetailId="{ item }">
            <td nowrap="true" class="text-truncate" style="max-width: 150px;">{{ item.DetailId }}</td>
          </template>
        </v-data-table> -->
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import {
  FETCH_RISK_APPROVAL,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import Util from "@/common/util";
export default {
  data: () => ({
    riskApproval: {},
  }),
  computed: {
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
    approvalStatusList () {
      return Util.categories('ApprovalStatus')
    },
  },
  async created() {
    try {
      const data = await this.$store.dispatch(FETCH_RISK_APPROVAL, this.$route.params.id);

      this.riskApproval = data;
    } catch (error) {
      store.commit(SET_ERROR, { msg: error.response.data.message });
    }
  },
  methods: {
    goBack() {
      this.$router.push({ path: `/dealer/risk/approval` });
    },
  }
}
</script>