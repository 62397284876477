<template>
  <v-container fluid>
    <v-row class="fill-height overflow-y-auto mb-16">
      <v-col cols="12" md="6" v-for="(item, index) in riskApprovalList" :key="index">
        <v-card class="block-item elevation-2" @click="goTo(item)">
          <v-card-text>
            <div class="block-content">
              <div class="text-content">
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>流水号：{{ item.serialNumber }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters>
                          <v-col cols="auto">客户名称：</v-col>
                          <v-col class="text-wrap">{{ item.enterprise.name }}</v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>付款条件：{{payConditionList.find(n => n.code.toString() === item.payCondition).name}}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row>
                          <v-col>申请账期：{{ item.days }}天</v-col>
                          <v-col>申请额度：{{ item.quota | formatMoney }}</v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row>
                          <v-col>{{ item.creator.personal.name }}</v-col>
                          <v-col>{{ item.createTime | formatTime }}</v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <div class="action-button">
                <v-icon>keyboard_arrow_right</v-icon>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-progress-circular
          v-if="this.query.page * this.query.limit < riskApprovalCount"
          indeterminate
          color="secondary"
          width="2"
          v-intersect="{
          handler: infiniteScrolling,
          options: {
              threshold: [1.0]
          }
        }"
        ></v-progress-circular>
        <span v-else class="font-weight-light">已展示{{riskApprovalCount}}个授信审批，没有更多数据了。</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
import { 
  FETCH_RISK_APPROVAL_LIST,
} from "@/store/actions.type";
import { mapGetters } from 'vuex';
import Util from "@/common/util";
export default {
  data: () => ({
    riskApprovalList: [],
    query: {
      key: '',
      enterprise: '',
      page: 1,
      limit: 10,
    },
  }),
  computed: {
    ...mapGetters([
      "currentEmployer",
      "riskApprovalLoading",
      "riskApprovalCount",
    ]),
    payConditionList () {
      return Util.categories('PayCondition')
    },
  },
  async created() {
    await this.getRiskApprovalList()
  },
  methods: {
    async getRiskApprovalList() {
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let strQuery = 'needEspProcess=true';
      if (this.query.enterprise) strQuery += '&enterprise=' + this.query.enterprise
      if (this.query.page) strQuery += '&page=' + this.query.page
      if (this.query.limit) strQuery += '&limit=' + this.query.limit
      const data = await store.dispatch(FETCH_RISK_APPROVAL_LIST, strQuery);
      this.riskApprovalList = this.riskApprovalList.concat(data.docs);
    },
    infiniteScrolling() {
      this.query.page++;
      this.getRiskApprovalList();
    },
    goTo(item) {
      this.$router.push({ path: '/dealer/creditline/review/detail/'+ item._id });
    },
  },
}
</script>
<style scoped>
.block-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-content {
  flex-grow: 1;
  margin-right: 16px;
}

.action-button {
  display: flex;
  align-items: center;
}

.text-wrap {
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
</style>
