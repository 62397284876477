<template>
  <v-container>
    <v-dialog
      :value="assetServiceLoading ||
      assetRepairLoading ||
      consultingServiceLoading ||
      rentOfferLoading ||
      orderPurchaseLoading ||
      riskLoading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-text>
          数据加载中...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar class="secondary" dark flat>
        <v-toolbar-title>工作台</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card>
    <v-row class="pa-4">
      <draggable :disabled="$vuetify.breakpoint.mobile" v-model="cardOrder" class="row" v-bind="dragOptions">
        <v-col md="6" sm="12" v-for="(layouts, i) in cardOrder" :key="layouts.index">
          <draggable :disabled="$vuetify.breakpoint.mobile" :list="layouts.items" :group="{ name: 'card' }" v-bind="dragOptions" :move="changeCard" @end="endCard">
            <v-col cols="12" v-for="(card, j) in layouts.items.filter(l => l.code !== 'delivery')" :key="card.code">
              <v-card outlined :loading="
              card.code === 'maintain' ? assetRepairLoading
              : card.code === 'service' ? assetServiceLoading
              : card.code === 'advisory' ? consultingServiceLoading
              : card.code === 'rentoffer' ? rentOfferLoading
              : card.code === 'purchase' ? purchaseProductLoading
              : card.code === 'order' ? orderPurchaseLoading
              : riskLoading">
                <v-row no-gutters align="start">
                  <v-col cols="2"><v-icon v-if="!$vuetify.breakpoint.mobile" small style="cursor: move">drag_indicator</v-icon></v-col>
                  <v-col cols="8"><span class="subtitle-2" v-if="card.close">{{ card.name }}</span></v-col>
                  <v-col cols="2" class="text-right"><v-btn icon x-small @click="foldCard([i,j])"><v-icon small>{{card.close ? 'open_in_full' : 'close_fullscreen'}}</v-icon></v-btn></v-col>
                </v-row>
                <div v-if="!card.close">
                  <v-row align="center" justify="space-between">
                    <v-col cols="12" md="6">
                      <v-card-title>{{ card.name }}</v-card-title>
                      <v-card-text>{{ card.text }}</v-card-text>
                    </v-col>
                    <v-col cols="12" md="6" v-if="!$vuetify.breakpoint.mobile">
                      <v-img :src="card.image" max-width="450px" height="135px" contain class="ma-2"></v-img>
                    </v-col>
                  </v-row>
                  <template v-for="list in card.data.data">
                    <v-divider :key="list._id +'_hr'"></v-divider>
                    <v-list-item link :key="list._id" @click="$router.push({ path: card.path +'?'+ converPath(card.code) + list._id +'&enterprise='+ query.enterprise})">
                      <v-list-item-content>
                        <v-list-item-title>{{ list.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-spacer></v-spacer>
                      <v-list-item-content>
                        <v-list-item-title>{{ list.count }}</v-list-item-title>
                      </v-list-item-content>
                      <v-icon class="subheading font-weight-light">arrow_forward</v-icon>
                    </v-list-item>
                  </template>
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-icon class="mr-2" small>schedule</v-icon>
                    <span class="caption font-weight-light">更新：{{card.data.createTime | fromDate}}</span>
                    <v-icon v-if="card.group" class="ml-2" small @click="syncData(card.group, [i,j])">sync</v-icon>
                    <v-spacer></v-spacer>
                    <v-btn text plain @click="$router.push({ path: card.path +'?enterprise='+ query.enterprise})">查看全部({{card.data.count}})<v-icon right>keyboard_arrow_right</v-icon></v-btn>
                  </v-list-item>
                </div>
              </v-card>
            </v-col>
          </draggable>
        </v-col>
      </draggable>
    </v-row>
  </v-container>
</template>
<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_REPORT_CASEGROUP_COUNT, FETCH_REPORT_ORDER_PURCHASE_STATUS,
  FETCH_REPORT_RENT_OFFER_STATUS, FETCH_REPORT_PURCHASE_STATUS, FETCH_REPORT_RISK_COUNT, 
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      assetServiceLoading: true,
      assetRepairLoading: true,
      consultingServiceLoading: true,
      rentOfferLoading: true,
      orderPurchaseLoading: true,
      purchaseProductLoading: true,
      riskLoading: true,
      query: {
        enterprise: '',
      },
      cardOrder: [
        { index: 'left', items: []},
        { index: 'right', items: []},
      ],
      maxRow: 3,
    }
  },
  created() {
    this.getList()
  },
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  methods: {
    async getList() {
      let urlQuery = '&source=cache'
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.enterprise) urlQuery += '&enterprise='+ this.query.enterprise
      }

      let localCard = localStorage.getItem('esp_workbench_30')
      if (localCard) this.cardOrder = JSON.parse(localCard)

      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Service_Edit')) {
        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=asset_service'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'service',
              name: '设备服务',
              text: '设备管理服务汇总信息',
              data,
              image: '/static/workflow/asset_service.svg',
              image_width: 450,
              path: '/dealer/workbench/service/list',
              group: 'asset_service',
              list: 'assetServiceList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'service')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'service')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.assetServiceLoading = false
          })
      } else {
        this.assetServiceLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Maintain_Edit')) {
        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=asset_repair'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'maintain',
              name: '维修服务',
              text: '设备维修服务汇总信息',
              data,
              image: '/static/workflow/maintain.svg',
              image_width: 450,
              path: '/dealer/workbench/maintain/list',
              group: 'asset_repair',
              list: 'assetRepairList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'maintain')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'maintain')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.assetRepairLoading = false
          })
      } else {
        this.assetRepairLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Advisory_Edit')) {
        await store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=consulting_service'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'advisory',
              name: '咨询服务',
              text: '企业用户咨询汇总信息',
              data,
              image: '/static/workflow/advisory.svg',
              image_width: 450,
              path: '/dealer/workbench/advisory/list',
              group: 'consulting_service',
              list: 'consultingServiceList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'advisory')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'advisory')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.consultingServiceLoading = false
          })
      } else {
        this.consultingServiceLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Order_Sale') || this.currentUser.permission.includes('Order_Service') || this.currentUser.permission.includes('Order_Create')) {
        await store.dispatch(FETCH_REPORT_ORDER_PURCHASE_STATUS, '?'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'order',
              name: '订单管理',
              text: '企业订单相关信息汇总',
              data,
              image: '/static/workflow/order.svg',
              image_width: 450,
              path: '/dealer/workbench/order/list',
              group: 'purchase_order',
              list: 'purchaseList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'order')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'order')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.orderPurchaseLoading = false
          })
      } else {
        this.orderPurchaseLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') ||
        this.currentUser.permission.includes('Order_Sale') ||
        this.currentUser.permission.includes('Order_Service') ||
        this.currentUser.permission.includes('Order_Create') ||
        this.currentUser.permission.includes('Purchase_Service') ||
        this.currentUser.permission.includes('Purchase_Admin')) {
        await store.dispatch(FETCH_REPORT_PURCHASE_STATUS, '?'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'purchase',
              name: '外采请求',
              text: '外采服务相关请求信息汇总',
              data,
              image: '/static/workflow/purchase.svg',
              image_width: 450,
              path: '/dealer/workbench/purchase/list',
              group: 'purchase_product',
              list: 'purchaseProductList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'purchase')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'purchase')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.purchaseProductLoading = false
          })
      } else {
        this.purchaseProductLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('Lease_Sale')) {
        await store.dispatch(FETCH_REPORT_RENT_OFFER_STATUS, '?'+ urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'rentoffer',
              name: '租赁报价',
              text: '为企业提供租赁报价及账单计算',
              data,
              image: '/static/workflow/rental.svg',
              image_width: 450,
              path: '/dealer/workbench/rent/list',
              group: 'rent_offer',
              list: 'rentOfferList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'rentoffer')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'rentoffer')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.rentOfferLoading = false
          })
      } else {
        this.rentOfferLoading = false
      }
      if (this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskAdmin') || this.currentUser.permission.includes('System_RiskStaff')) {
        await store.dispatch(FETCH_REPORT_RISK_COUNT, '?groupname=risk' + urlQuery)
          .then((data) => {
            let numLayout = this.cardOrder[0].items.length < this.maxRow ? 0 : 1
            let objCard = {
              code: 'risk',
              name: '信控中心',
              text: '企业信控管理活动信息',
              data,
              image: '/static/workflow/service.svg',
              image_width: 450,
              path: '/dealer/risk/approval',
              group: 'risk',
              list: 'assetRepairList'
            }
            let leftIndex = this.cardOrder[0].items.findIndex(edit => edit.code === 'risk')
            let rightIndex = this.cardOrder[1].items.findIndex(edit => edit.code === 'risk')
            if (leftIndex > -1) {
              Object.assign(this.cardOrder[0].items[leftIndex], objCard)
            } else if (rightIndex > -1){
              Object.assign(this.cardOrder[1].items[rightIndex], objCard)
            } else {
              this.cardOrder[numLayout].items.push(objCard)
            }
            this.riskLoading = false
          })
      } else {
        this.riskLoading = false
      }
    },
    syncData(group = 'asset_service', listIndex) {
      let urlQuery = ''
      urlQuery = '&source=database'
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
        if (this.query.enterprise) urlQuery += '&enterprise=' + this.query.enterprise
      }

      if (group === 'rent_offer') {
        this.rentOfferLoading = true
        store.dispatch(FETCH_REPORT_RENT_OFFER_STATUS, '?'+ urlQuery)
          .then((data) => {
            store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
            this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            this.rentOfferLoading = false
          })
      } else if (group === 'purchase_order') {
        this.orderPurchaseLoading = true
        store.dispatch(FETCH_REPORT_ORDER_PURCHASE_STATUS, '?' + urlQuery)
          .then((data) => {
            store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
            this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            this.orderPurchaseLoading = false
          })
      } else if (group === 'purchase_product') {
        this.purchaseProductLoading = true
        store.dispatch(FETCH_REPORT_PURCHASE_STATUS, '?' + urlQuery)
          .then((data) => {
            store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
            this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            this.purchaseProductLoading = false
          })
      } else if (group === 'risk') {
        this.riskLoading = true
        store.dispatch(FETCH_REPORT_RISK_COUNT, '?' + urlQuery)
          .then((data) => {
            store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
            this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            this.riskLoading = false
          })
      } else {
        if (group === 'consulting_service') this.consultingServiceLoading = true
        if (group === 'asset_repair') this.assetRepairLoading = true
        if (group === 'asset_service') this.assetServiceLoading = true
        store.dispatch(FETCH_REPORT_CASEGROUP_COUNT, '?groupname=' + group + urlQuery)
          .then((data) => {
            store.commit(SET_ERROR, {msg: '数据更新成功。', color: 'primary'});
            this.cardOrder[listIndex[0]].items[listIndex[1]].data = data
            this.consultingServiceLoading = false
            this.assetRepairLoading = false
            this.assetServiceLoading = false
          })
      }

    },
    changeCard: function(evt){
      const lastItem = this.cardOrder.find(element => element.items.length === 1)
      const moveItem = evt.draggedContext.element
      if (lastItem && lastItem.items.find(item => item.code === moveItem.code)) {
        return false
      } else {
        return
      }
    },
    converPath(code) {
      switch (code) {
        case 'advisory': {
          return 'group='
        }
        case 'order': case 'purchase': case 'rentoffer': {
          return 'status='
        }
        default: {
          return 'service='
        }
      }
    },
    endCard() {
      let objLocal = [
        {
          index: 'left',
          items: this.cardOrder[0].items
        },
        {
          index: 'right',
          items: this.cardOrder[1].items
        }
      ]
      localStorage.setItem('esp_workbench_30', JSON.stringify(objLocal))
    },
    foldCard(listIndex) {
      this.cardOrder[listIndex[0]].items[listIndex[1]].close = !this.cardOrder[listIndex[0]].items[listIndex[1]].close;
      this.endCard();
      this.$forceUpdate();
    },
  },
}
</script>
