<template>
  <v-container>
    <v-card>
      <v-toolbar flat class="secondary" dark>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>准入尽职调查结果</v-toolbar-title>

      </v-toolbar>
      <v-card-text>
        <v-subheader>风险等级</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            企业名称
          </v-col>
          <v-col cols="4">
            {{ editedItem.CompanyName }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            风险等级
          </v-col>
          <v-col cols="4">
            {{ editedItem.RiskLevel }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2">
            尽调评分
          </v-col>
          <v-col cols="4">
            {{ editedItem.RiskScore }}
          </v-col>
        </v-row>
        <v-subheader>尽调结果</v-subheader>
        <v-row>
          <v-col cols="12"><v-divider></v-divider></v-col>
        </v-row>
        <v-data-table
          :headers="headerTable"
          :items="editedItem.Data"
          :loading="qccAcctScanLoading"
          loading-text="数据读取中... 请稍后"
          fixed-header
          height="600"
          transition
        >
        <template v-slot:item.DetailId="{ item }">
          <td nowrap="true" class="text-truncate" style="max-width: 150px;">{{item.DetailId}}</td>
        </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";
import {
  FETCH_QCC_ACCT_SCAN,
} from "@/store/actions.type";
export default {
  data: () => ({
    qccAcctScanLoading: false,
    editedItem: {},
    headerTable: [
      {text: '标题', width: '200px', value: 'Title', sortable: false},
      {text: '描述', width: '400px', value: 'Description', sortable: false},
      {text: '红黄绿通道', width: '100px', value: 'Passage', sortable: false},
      {text: '风险类别', width: '150px', value: 'RiskType', sortable: false},
      {text: '详情Id', width: '125px', value: 'DetailId', sortable: false},
      {text: '详情分类', width: '125px', value: 'TypeCode', sortable: false},
    ],
  }),
  async created() {
    this.qccAcctScanLoading = true;
    try {
      const data = await store.dispatch(FETCH_QCC_ACCT_SCAN, {
        params: {
          key: this.$route.params.name,
        }
      });

      this.editedItem = data.result;
    } catch (error) {
      store.commit(SET_ERROR, { msg: error.response.data.message });
    }
    this.qccAcctScanLoading = false;
  },
  methods: {
    goBack() {
      this.$router.push({path: `/dealer/system/risk/enterprise`});
    },
  }
}
</script>
