var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    staticClass: "secondary",
    attrs: {
      "dark": "",
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/dealer/workbench');
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("订单服务列表")]), _c('v-spacer'), _c('v-text-field', {
    staticClass: "mx-4",
    attrs: {
      "flat": "",
      "dense": "",
      "hide-details": "",
      "label": "搜索订单编号、PO号、DT号",
      "prepend-inner-icon": "search",
      "solo-inverted": ""
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.doAction('search_item');
      }
    },
    model: {
      value: _vm.query.key,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "key", $$v);
      },
      expression: "query.key"
    }
  })], 1), _c('v-alert', {
    staticClass: "ma-2",
    attrs: {
      "dense": "",
      "type": "info",
      "dismissible": ""
    }
  }, [_vm._v(" 订单服务是经销商为企业提供采购和租赁订单的处理模块，通过订单可以推送相关任务工单和外采工单。 ")]), _c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.computedHeaders,
      "items": _vm.orderPurchaseList,
      "server-items-length": _vm.orderPurchaseCount,
      "loading": _vm.orderPurchaseLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": "",
      "height": "600"
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          attrs: {
            "align-with-title": ""
          },
          model: {
            value: _vm.active_tab,
            callback: function callback($$v) {
              _vm.active_tab = $$v;
            },
            expression: "active_tab"
          }
        }, [_c('v-tabs-slider', {
          attrs: {
            "color": "secondary"
          }
        }), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('all');
            }
          }
        }, [_vm._v("全部订单")]), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('purchase');
            }
          }
        }, [_vm._v("采购订单")]), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('rental');
            }
          }
        }, [_vm._v("租赁订单")]), _c('v-tab', {
          on: {
            "click": function click($event) {
              return _vm.todoList('follow');
            }
          }
        }, [_vm._v("我的关注")])], 1), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Order_Service') || _vm.currentUser.permission.includes('Order_Create') ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                  attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "secondary",
                  "depressed": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("add")]), _vm._v(" 新建订单 ")], 1)];
            }
          }], null, false, 402066605)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.openNewOrder('purchase');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("shopping_cart")])], 1), _c('v-list-item-title', [_vm._v("采购订单")])], 1), _c('v-list-item', {
          on: {
            "click": function click($event) {
              return _vm.openNewOrder('rent');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', [_vm._v("compare_arrows")])], 1), _c('v-list-item-title', [_vm._v("租赁订单")])], 1)], 1)], 1) : _vm._e(), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _vm.currentUser.hasAdmin.includes('dealer') || _vm.currentUser.permission.includes('Order_Service') || _vm.currentUser.permission.includes('Order_Create') ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref2) {
              var on = _ref2.on,
                  attrs = _ref2.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": "",
                  "loading": _vm.loadingDownload
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('export_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("save_alt")])], 1)];
            }
          }], null, false, 881683684)
        }, [_c('span', [_vm._v("导出数据")])]) : _vm._e(), _c('widgets-custom-header', {
          attrs: {
            "defaultHeader": _vm.defaultHeader,
            "headerList": _vm.headerItem,
            "version": 2,
            "tableName": "orderHeader"
          },
          on: {
            "Cancel": function Cancel($event) {
              return _vm.doAction('cancel_header');
            },
            "Default": function Default($event) {
              return _vm.doAction('default_header');
            },
            "Save": function Save($event) {
              return _vm.doAction('set_header');
            }
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref3) {
              var on = _ref3.on,
                  attrs = _ref3.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    _vm.filterDrawer = !_vm.filterDrawer;
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.doAction('reload_item');
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("autorenew")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("刷新列表")])])], 1)];
      },
      proxy: true
    }, {
      key: "item.order_number",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('td', {
          staticClass: "secondary--text align-center",
          attrs: {
            "nowrap": "true"
          }
        }, [item.isMark ? _c('v-btn', {
          attrs: {
            "icon": "",
            "color": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.markOrder(item._id, false);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star")])], 1) : _c('v-btn', {
          attrs: {
            "icon": ""
          },
          on: {
            "click": function click($event) {
              return _vm.markOrder(item._id, true);
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "transition": "scale-transition"
          }
        }, [_vm._v("star_outline")])], 1), _c('v-badge', {
          attrs: {
            "color": "secondary",
            "dot": "",
            "value": false
          }
        }, [_c('widgets-order-dialogs', {
          key: item._id,
          attrs: {
            "getId": item._id
          },
          on: {
            "Update": _vm.updateList
          }
        }, [_vm._v(" " + _vm._s(item.order_number) + " ")])], 1)], 1)];
      }
    }, {
      key: "item.product_list",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('td', {
          staticClass: "v-list-item--link",
          on: {
            "click": function click($event) {
              _vm.productDlg = true;
            }
          }
        }, [_vm._v(" " + _vm._s(item.product_list) + " ")])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.status == '0' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "secondary"
          }
        }, [_vm._v("已接单")]) : _vm._e(), item.status == '1' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("处理中")]) : _vm._e(), item.status == '2' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已取消")]) : _vm._e(), item.status == '3' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已拒绝")]) : _vm._e(), item.status == '4' ? _c('v-chip', {
          attrs: {
            "label": "",
            "small": ""
          }
        }, [_vm._v("已完成")]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.createTime)))])];
      }
    }, {
      key: "item.updateTime",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(_vm._f("formatTime")(item.updateTime)))])];
      }
    }, {
      key: "item.count_case",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('td', {
          staticClass: "v-list-item--link",
          attrs: {
            "nowrap": "true"
          },
          on: {
            "click": function click($event) {
              _vm.caseDlg = true;
            }
          }
        }, [_vm._v(_vm._s(item.count_case) + "个")])];
      }
    }, {
      key: "item.count_asset",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.count_asset) + "台")])];
      }
    }, {
      key: "item.count_product",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_vm._v(" " + _vm._s(item.count_product) + "种 ")];
      }
    }, {
      key: "item.product_part_list",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_part_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "200px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(" " + _vm._s(pn || '无') + " ")])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_name_list",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, _vm._l(item.product_name_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            staticStyle: {
              "width": "200px"
            },
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-truncate",
            staticStyle: {
              "max-width": "200px"
            }
          }, [_vm._v(" " + _vm._s(pn) + " ")])])], 1);
        }), 0)];
      }
    }, {
      key: "item.product_quantity_list",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return _vm._l(item.product_quantity_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_asset_list",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return _vm._l(item.product_asset_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_await_list",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return _vm._l(item.product_await_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn))])])], 1);
        });
      }
    }, {
      key: "item.product_cancel_list",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return _vm._l(item.product_cancel_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn || 0))])])], 1);
        });
      }
    }, {
      key: "item.product_cases_list",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return _vm._l(item.product_cases_list, function (pn, i) {
          return _c('div', {
            key: i,
            staticClass: "my-2"
          }, [_c('v-chip', {
            attrs: {
              "label": "",
              "small": ""
            }
          }, [_c('span', {
            staticClass: "text-center",
            staticStyle: {
              "min-width": "40px"
            }
          }, [_vm._v(_vm._s(pn || 0))])])], 1);
        });
      }
    }, {
      key: "item.enterprise",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.enterprise ? _c('td', {
          staticClass: "v-list-item--link text-truncate",
          attrs: {
            "nowrap": "true",
            "width": "200"
          },
          on: {
            "click": function click($event) {
              return _vm.openEnterInfo(item.enterprise._id);
            }
          }
        }, [_vm._v(" " + _vm._s(item.enterprise.name) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.sales",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [item.sales ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.sales.personal ? item.sales.personal.name : item.sales.username))]) : _vm._e()];
      }
    }, {
      key: "item.editor",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [item.editor && item.editor.personal ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.editor.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [item.creator && item.creator.personal ? _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.creator.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.total_price",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("¥ " + _vm._s(_vm._f("formatMoney")(item.total_price.$numberDecimal)))])];
      }
    }, {
      key: "item.total_quantity",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_vm._v(" " + _vm._s(item.total_quantity) + "台 ")];
      }
    }, {
      key: "item.left_quantity",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.total_quantity - item.count_asset) + "台")])];
      }
    }, {
      key: "item.payment_day",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [_vm._v(" " + _vm._s(item.payment_day) + " ")];
      }
    }, {
      key: "item.rent_info.rent_type",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [item.rent_info ? _c('div', [_vm._v(" " + _vm._s(item.rent_info.rent_type === 0 ? '融资租赁' : '经营性租赁') + " ")]) : _c('div', [_vm._v(" 无 ")])];
      }
    }, {
      key: "item.rent_info.lease_start",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [item.rent_info ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.rent_info.lease_start)) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.rent_info.lease_term",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [item.rent_info ? _c('span', [_vm._v(" " + _vm._s(item.rent_info.lease_term / 365) + "年 ")]) : _vm._e()];
      }
    }, {
      key: "item.rent_info.payment_cycle",
      fn: function fn(_ref31) {
        var item = _ref31.item;
        return [item.rent_info ? _c('span', [item.rent_info.payment_cycle === 0 ? _c('span', [_vm._v(" 月付 ")]) : item.rent_info.payment_cycle === 1 ? _c('span', [_vm._v(" 季付 ")]) : _c('span', [_vm._v(" 半年付 ")])]) : _vm._e()];
      }
    }, {
      key: "item.rent_info.payment_period",
      fn: function fn(_ref32) {
        var item = _ref32.item;
        return [item.rent_info ? _c('span', [_vm._v(" " + _vm._s(item.rent_info.payment_period) + "天 ")]) : _vm._e()];
      }
    }, {
      key: "item.rent_info.total_period",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [item.rent_info ? _c('span', [_vm._v(" " + _vm._s(item.rent_info.total_period) + "期 ")]) : _vm._e()];
      }
    }, {
      key: "item.rent_info.expiry_date",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [item.rent_info ? _c('span', [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.rent_info.expiry_date)) + " ")]) : _vm._e()];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref35) {
        var item = _ref35.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref36) {
              var on = _ref36.on,
                  attrs = _ref36.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.copyOrder(item._id, item.type);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("content_copy")])], 1), _c('v-list-item-title', [_vm._v(" 复制 ")])], 1), item.type === 1 ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.leaseOrder(item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("autorenew")])], 1), _c('v-list-item-title', [_vm._v(" 续租 ")])], 1) : _vm._e()], 1)], 1)];
      }
    }])
  })], 1), _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.newOrderDlg,
      callback: function callback($$v) {
        _vm.newOrderDlg = $$v;
      },
      expression: "newOrderDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("选择企业")]), _c('v-card-text', [_c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "rules": [_vm.rules.selected],
      "label": "企业",
      "hint": "请选择一家企业",
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.orderItem.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.orderItem, "enterprise", $$v);
      },
      expression: "orderItem.enterprise"
    }
  })], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.newOrderDlg = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.openNewOrder
    }
  }, [_vm._v("确定")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "600"
    },
    model: {
      value: _vm.productDlg,
      callback: function callback($$v) {
        _vm.productDlg = $$v;
      },
      expression: "productDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("型号列表")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-12"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.proHeaders,
      "items": _vm.prodcutList,
      "hide-default-footer": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "body.append",
      fn: function fn() {
        return [_c('tr', {
          staticClass: "grey lighten-3 secondary--text"
        }, [_c('td', [_vm._v("合计")]), _c('td', [_vm._v("10")]), _c('td'), _c('td', [_vm._v("¥114995")])])];
      },
      proxy: true
    }, {
      key: "item.price",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("¥" + _vm._s(item.price))])];
      }
    }, {
      key: "item.price_total",
      fn: function fn(_ref38) {
        var item = _ref38.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("¥" + _vm._s(item.price_total))])];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.productDlg = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "width": "900"
    },
    model: {
      value: _vm.caseDlg,
      callback: function callback($$v) {
        _vm.caseDlg = $$v;
      },
      expression: "caseDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("工单列表")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-12"
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.caseHeaders,
      "items": _vm.caseList,
      "hide-default-footer": "",
      "dense": ""
    },
    scopedSlots: _vm._u([{
      key: "item.price",
      fn: function fn(_ref39) {
        var item = _ref39.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("¥" + _vm._s(item.price))])];
      }
    }, {
      key: "item.price_total",
      fn: function fn(_ref40) {
        var item = _ref40.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v("¥" + _vm._s(item.price_total))])];
      }
    }, {
      key: "item.status",
      fn: function fn(_ref41) {
        var item = _ref41.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_c('v-chip', {
          attrs: {
            "label": "",
            "small": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")])], 1)];
      }
    }])
  })], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.caseDlg = false;
      }
    }
  }, [_vm._v("关闭")])], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "600px",
      "persistent": ""
    },
    model: {
      value: _vm.enterInfoDlg,
      callback: function callback($$v) {
        _vm.enterInfoDlg = $$v;
      },
      expression: "enterInfoDlg"
    }
  }, [_c('widgets-enterprise-dialogs', {
    key: _vm.activeId,
    attrs: {
      "id": _vm.activeId
    },
    on: {
      "Close": _vm.closeEnterInfo
    }
  })], 1), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              _vm.filterDrawer = !_vm.filterDrawer;
            }
          }
        }, [_vm._v("隐藏")]), _c('v-btn', {
          attrs: {
            "color": "secondary",
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('clear_filter');
            }
          }
        }, [_vm._v("清空")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-card', {
    staticClass: "mx-auto",
    attrs: {
      "flat": ""
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "headline py-2"
  }, [_vm._v("筛选")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-sheet', {
    attrs: {
      "min-height": "500"
    }
  }, [_c('div', {
    staticClass: "mt-2"
  }, [_c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("客户")]), _c('v-autocomplete', {
    attrs: {
      "label": "请输入客户名称",
      "autocomplete": "off",
      "items": _vm.dealer_enterprise,
      "item-text": "name",
      "item-value": "_id",
      "hide-no-data": "",
      "outlined": "",
      "dense": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter_branch');
      }
    },
    model: {
      value: _vm.query.enterprise,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "enterprise", $$v);
      },
      expression: "query.enterprise"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "autocomplete": "off",
      "items": _vm.arrBranch,
      "label": "分支机构",
      "hint": "请选择企业的分支机构",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.branch,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "branch", $$v);
      },
      expression: "query.branch"
    }
  }), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("工单状态")]), _vm.orderStatus.length ? _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.status,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "status", $$v);
      },
      expression: "query.status"
    }
  }, _vm._l(_vm.orderStatus, function (orders) {
    return _c('v-chip', {
      key: orders.code,
      attrs: {
        "value": orders.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(orders.name))]);
  }), 1) : _vm._e(), _c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("创建时间")]), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref42) {
        var on = _ref42.on,
            attrs = _ref42.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.startCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "startCreateTime", $$v);
            },
            expression: "query.startCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuStartCreateTime,
      callback: function callback($$v) {
        _vm.menuStartCreateTime = $$v;
      },
      expression: "menuStartCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuStartCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.startCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "startCreateTime", $$v);
      },
      expression: "query.startCreateTime"
    }
  })], 1), _c('v-menu', {
    ref: "menuStartCreateTime",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref43) {
        var on = _ref43.on,
            attrs = _ref43.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.endCreateTime,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "endCreateTime", $$v);
            },
            expression: "query.endCreateTime"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.menuEndCreateTime,
      callback: function callback($$v) {
        _vm.menuEndCreateTime = $$v;
      },
      expression: "menuEndCreateTime"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuEndCreateTime = false;

        _vm.clearTime(['createTime']);
      }
    },
    model: {
      value: _vm.query.endCreateTime,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "endCreateTime", $$v);
      },
      expression: "query.endCreateTime"
    }
  })], 1), this.query.types.includes('rent') ? _c('div', [_c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("租赁方式")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.rent_type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "rent_type", $$v);
      },
      expression: "query.rent_type"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "0"
    }
  }, [_vm._v("融资租赁")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "1"
    }
  }, [_vm._v("经营性租赁")])], 1), _c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("租期")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.lease_term,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "lease_term", $$v);
      },
      expression: "query.lease_term"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "365"
    }
  }, [_vm._v("1年")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "730"
    }
  }, [_vm._v("2年")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "1095"
    }
  }, [_vm._v("3年")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "1460"
    }
  }, [_vm._v("4年")])], 1), _c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("起租日期")]), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref44) {
        var on = _ref44.on,
            attrs = _ref44.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.leaseStartFrom,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "leaseStartFrom", $$v);
            },
            expression: "query.leaseStartFrom"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 655212341),
    model: {
      value: _vm.menuLeaseStartFrom,
      callback: function callback($$v) {
        _vm.menuLeaseStartFrom = $$v;
      },
      expression: "menuLeaseStartFrom"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuLeaseStartFrom = false;
      }
    },
    model: {
      value: _vm.query.leaseStartFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "leaseStartFrom", $$v);
      },
      expression: "query.leaseStartFrom"
    }
  })], 1), _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref45) {
        var on = _ref45.on,
            attrs = _ref45.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          staticClass: "mt-2 mb-n4",
          attrs: {
            "label": "结束时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": ""
          },
          on: {
            "change": function change($event) {
              return _vm.doAction('set_filter');
            }
          },
          model: {
            value: _vm.query.leaseStartTo,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "leaseStartTo", $$v);
            },
            expression: "query.leaseStartTo"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }], null, false, 1619484479),
    model: {
      value: _vm.menuLeaseStartTo,
      callback: function callback($$v) {
        _vm.menuLeaseStartTo = $$v;
      },
      expression: "menuLeaseStartTo"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": "",
      "width": "225"
    },
    on: {
      "input": function input($event) {
        _vm.doAction('set_filter');

        _vm.menuLeaseStartTo = false;
      }
    },
    model: {
      value: _vm.query.leaseStartTo,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "leaseStartTo", $$v);
      },
      expression: "query.leaseStartTo"
    }
  })], 1), _c('h2', {
    staticClass: "subtitle-2 my-2"
  }, [_vm._v("支付周期")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    on: {
      "change": function change($event) {
        return _vm.doAction('set_filter');
      }
    },
    model: {
      value: _vm.query.payment_cycle,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "payment_cycle", $$v);
      },
      expression: "query.payment_cycle"
    }
  }, [_c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "0"
    }
  }, [_vm._v("月付")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "1"
    }
  }, [_vm._v("季付")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "2"
    }
  }, [_vm._v("半年付")]), _c('v-chip', {
    attrs: {
      "filter": "",
      "label": "",
      "outlined": "",
      "value": "3"
    }
  }, [_vm._v("年付")])], 1)], 1) : _vm._e()], 1)])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }