<template>
  <v-container fluid>
    <v-card class="mx-auto" max-width="90%" outlined>
      <v-img src="/static/system/rent.png" height="200px" contain></v-img>

      <v-card-title>
        ESP风控系统移动端
      </v-card-title>

      <v-card-subtitle>
        ESP风控系统移动端是为倍升公司内部销售团队提供的授信申请工具，该工具需要在飞书内运行，同时需要在ESP后台开通相应权限后即可使用。如在此页面底部未提供功能入口，请联系风控小组获取权限。
      </v-card-subtitle>

      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="show = !show"
        >
          查看您的权限
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="show = !show"
        >
          <v-icon>{{ show ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="show">
          <v-divider></v-divider>
          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>实地尽调</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="green" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
                    check_circle
                  </v-icon>
                  <v-icon color="red" v-else>
                    block
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>授信申请</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="green" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskStaff') || this.currentUser.permission.includes('System_RiskAdmin')">
                    check_circle
                  </v-icon>
                  <v-icon color="red" v-else>
                    block
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>授信审批</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="green" v-if="this.currentUser.hasAdmin.includes('dealer') || this.currentUser.permission.includes('System_RiskAdmin')">
                    check_circle
                  </v-icon>
                  <v-icon color="red" v-else>
                    block
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>

          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data: () => ({
    show: false
  }),
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    goTo() {
      this.$router.push({ path: '/dealer/creditline/review' });
    },
    goTo1() {
      this.$router.push({ path: '/dealer/creditline/apply' });
    },
    goTo2() {
      location.href = 'https://doublerise.feishu.cn/share/base/form/shrcn8HCtFeyrSlf9Iso56qPi1b';
    },
  },
}
</script>
<style scoped>

</style>
