<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>企业授信</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="query.key"
          @keydown.enter="doAction('search_enterprise')"
          dark
          class="mx-4"
          flat
          dense
          hide-details
          label="搜索公司名称"
          prepend-inner-icon="search"
          solo-inverted
        ></v-text-field>
      </v-toolbar>
      <!-- <v-alert dense type="info" class="ma-2" dismissible>
        企业报表是为企业提供月度统计信息的报告。只有经过经销商人员编辑后且发布的报告才会在企业端呈现。
        <span class="font-weight-bold">月度报告每个月第一天0点系统自动生成</span>
      </v-alert> -->
      <v-data-table
        :headers="listHeader"
        :items="riskApprovalList"
        :options.sync="list_options"
        :server-items-length="riskApprovalCount"
        :loading="riskApprovalLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10,15, 30, 50, 100]}"
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title><v-icon class="mr-2">new_releases</v-icon>{{ query.enterprise ? monthlyEnterpriseList.find(item => item._id === query.enterprise).name : '企业授信审批'}}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="doAction('filter_approval')">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:item.serialNumber="{ item }">
          <td class="secondary--text v-list-item--link" @click="doAction('open_approval', item)">
            {{ item.serialNumber }}
          </td>
        </template>
        <template v-slot:item.payCondition="{ item }">
          <td nowrap="true">{{payConditionList.find(n => n.code.toString() === item.payCondition).name}}</td>
        </template>
        <template v-slot:item.instanceStatus="{ item }">
          <td nowrap="true">{{ approvalStatusList.find(n=>n.code == item.instanceStatus)?.name }}</td>
        </template>
        <template v-slot:item.createTime="{ item }">
          <td nowrap="true">{{item.createTime | formatTime}}</td>
        </template>
        <template v-slot:item.actions="{ item }">
          <td nowrap="true" width="50px">
            <widgets-monthly-report :getId="item._id" v-on:Close="closeItem">
              <v-icon small>edit</v-icon>
            </widgets-monthly-report>
          </td>
        </template>
      </v-data-table>
    </v-card>
    <v-navigation-drawer v-model="filterDrawer" app right>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            过滤条件
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-card-text>
        <h2 class="subtitle-2 mt-2">业务类型</h2>
        <v-chip-group v-model="query.businessType" column multiple @change="doAction('set_filter')">
          <v-chip v-for="type in businessTypeList" :key="type.code" :value="type.code" filter label outlined>{{type.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">新/旧客户</h2>
        <v-chip-group v-model="query.cusType" column multiple @change="doAction('set_filter')">
          <v-chip v-for="type in cusTypeList" :key="type.code" :value="type.code" filter label outlined>{{type.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">付款条件</h2>
        <v-chip-group v-model="query.payCondition" column multiple @change="doAction('set_filter')">
          <v-chip v-for="type in payConditionList" :key="type.code" :value="type.code" filter label outlined>{{type.name}}</v-chip>
        </v-chip-group>
        <h2 class="subtitle-2 mt-2">状态</h2>
        <v-chip-group v-model="query.approvalStatus" column multiple @change="doAction('set_filter')">
          <v-chip v-for="type in approvalStatusList" :key="type.code" :value="type.code" filter label outlined>{{type.name}}</v-chip>
        </v-chip-group>
      </v-card-text>
      <template v-slot:append>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="filterDrawer = !filterDrawer">隐藏</v-btn>
          <v-btn color="secondary" text @click="doAction('clear_filter')">清空</v-btn>
        </v-card-actions>
      </template>
    </v-navigation-drawer>
    <v-dialog v-model="dlgInfo" persistent scrollable max-width="600px">
      <widgets-enterprise-dialogs v-if="activeType === 'enterprise'" :id="activeId" :key="activeId" v-on:Close="doAction('close_info')"></widgets-enterprise-dialogs>
    </v-dialog>
  </v-container>
</template>

<script>
import store from "@/store";
import Util from '@/common/util';
import {
  FETCH_RISK_APPROVAL_LIST
} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      filterDrawer: false,
      query: {
        enterprise: '',
        businessType: [],
        cusType: [],
        payCondition: [],
        approvalStatus: [],
      },
      defaultQuery: {
        enterprise: '',
        businessType: [],
        cusType: [],
        payCondition: [],
        approvalStatus: [],
      },
      list_options: {},
      listHeader: [
        { text: '流水号', value: 'serialNumber' },
        { text: '待授信公司工商全称', value: 'enterprise.name' },
        { text: '付款条件', value: 'payCondition' },
        { text: '申请账期（天）', value: 'days' },
        { text: '申请额度（元）', value: 'quota' },
        { text: '当前审批节点', value: 'taskNode' },
        { text: '状态', value: 'instanceStatus' },
        { text: '申请时间', value: 'createTime' },
      ],
      dlgInfo: false,
      activeType: '',
      activeId: null,
    };
  },
  created() {
    this.getRiskApprovalList()
  },
  computed: {
    ...mapGetters([
      "currentEmployer",
      "riskApprovalLoading",
      "riskApprovalList",
      "riskApprovalCount",
      "monthlyEnterpriseList",
    ]),
    businessTypeList () {
      return Util.categories('RiskBusinessType')
    },
    payConditionList () {
      return Util.categories('PayCondition')
    },
    approvalStatusList () {
      return Util.categories('ApprovalStatus')
    },
    cusTypeList () {
      return Util.categories('CusType')
    },
  },
  watch: {
    list_options: {
      handler() {
        this.getRiskApprovalList(this.query)
      },
      deep: true,
    }
  },
  mounted() {},
  methods: {
    getRiskApprovalList(query = {}) {
      // 获取分页信息
      const { sortBy, sortDesc, page, itemsPerPage } = this.list_options;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true){
          sort = '-'+ sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0){
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key='+ this.query.key;
      if (this.query.businessType.length) urlQuery += '&businessType='+ this.query.businessType;
      if (this.query.cusType.length) urlQuery += '&cusType='+ this.query.cusType;
      if (this.query.payCondition.length) urlQuery += '&payCondition='+ this.query.payCondition;
      if (this.query.approvalStatus.length) urlQuery += '&approvalStatus='+ this.query.approvalStatus;
      if (Object.keys(this.list_options).length > 0) {
        store.dispatch(FETCH_RISK_APPROVAL_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    closeItem() {
      this.getRiskApprovalList(this.query);
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'create_item': {
          this.$router.push({path:'/dealer/risk/approval/edit' })
          break
        }
        case 'set_filter': {
          this.$router.push({ query: {} });
          this.getRiskApprovalList(this.query);
          break
        }
        case 'search_enterprise': {
          if (this.query.enterprise !== '') {
            this.getRiskApprovalList({enterprise: this.query.enterprise})
          } else {
            this.getRiskApprovalList()
          }
          break
        }
        case 'open_approval': {
          this.$router.push({ path: '/dealer/risk/approval/detail/' + item._id })
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'filter_approval': {
          this.filterDrawer = !this.filterDrawer
          break
        }
        case 'clear_filter': {
          this.query = Object.assign({}, this.defaultQuery);
          this.getRiskApprovalList();
          this.filterDrawer = !this.filterDrawer;
          break
        }
      }
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>
